import '../ErrorPage/ErrorPage.scss';
import Translations from '../../translations/translations.json';

const ErrorPage = () => {
  return (
    <div className="container">
      <div className="card">
        <h2 className="card__header">{Translations['page-not-found']}</h2>
        <div className="card__info">
          {Translations['error-invalid-token-msg-1']}
        </div>
        <div className="card__info">
          {Translations['error-invalid-token-msg-2']}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
