import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, Form, InjectedFormProps, reduxForm, change } from "redux-form";
import { Driver, EmploymentHistoryFormValues } from "../../types";
import { general, numberFieldValidation } from "../../validators";
import ReduxDatepicker from "../redux-ui/ReduxDatepicker";
import Translations from "../../translations/translations.json";
import ReduxInput from "../redux-ui/ReduxInput";

import ReduxToggleButton from "../redux-ui/ReduxToggleButton";
import Button from "../ui/Button/Button";
import useFormValue from "../../hooks/useFormValue";
import ReduxRadioGroup from "../redux-ui/ReduxRadioGroup";
import Fields from "../redux-ui/FieldArrayComponents/Fields/Fields.component";
import MotorVehicleAccidents from "../redux-ui/FieldArrayComponents/MotorVehicleAccidents/MotorVehicleAccidents";
import CheckBoxGroup from "../redux-ui/ReduxCheckBoxGroup";
import useQueryParams from "../../hooks/useQueryParams";
import jwtDecode from "jwt-decode";
import api from "../../api";
import { ClipLoader } from "react-spinners";

import { scrollToFirstValidationError } from "../../utils/scrollToFirstValidationError";
import { useDispatch } from "react-redux";
import { globalValidators } from "../../utils";
import ReduxCheckbox from "../redux-ui/ReduxCheckbox/ReduxCheckbox.component";

import {
  isEligibleForRehireOptions,
  reasonForLeaving,
  typeOfEquipmentOperated,
  workHour,
  workPlace,
} from "./config";
import "./EmploymentHistoryForm.styles.scss";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

type DecodeTokenValues = {
  driverId: string;
  employmentHistoryId: string;
};

const EmploymentHistoryForm = (
  props: InjectedFormProps<Record<string, EmploymentHistoryFormValues>>
) => {
  const { handleSubmit } = props;

  const {
    params: { code },
  } = useQueryParams<{ code: string }>();

  const [driver, setDriver] = useState<Driver>();
  const [loading, setLoading] = useState(false);
  const [decodedTokenValues, setDecodedTokenValues] =
    useState<DecodeTokenValues>();

  const history = useHistory();

  const noAccidents = useFormValue("noAccidents");
  const hadBreathAlcoholTest = useFormValue("hadBreathAlcoholTest");
  const hadPositiveDrugTest = useFormValue("hadPositiveDrugTest");
  const hasRefusedControlledSubstanceTest = useFormValue(
    "hasRefusedControlledSubstanceTest"
  );
  const hasViolatedDOT = useFormValue("hasViolatedDOT");
  const previousEmployerDrugAndAlcohol = useFormValue(
    "previousEmployerDrugAndAlcohol"
  );
  const isEligibleForRehire = useFormValue("isEligibleForRehire");

  const dispatch = useDispatch();

  const handleEmploymentHistorySubmit = useCallback(
    async (values: Partial<EmploymentHistoryFormValues>) => {
      try {
        setLoading(true);
        const {
          workHour,
          workPlace,
          isEligibleForRehire,
          companyPolicy,
          ...restValues
        } = values;

        if (restValues?.["noAccidents"] !== undefined)
          delete restValues["noAccidents"];

        if (decodedTokenValues) {
          await api.employmentHistory.fillEmploymentHistoryForm(
            decodedTokenValues.employmentHistoryId,
            decodedTokenValues.driverId,
            {
              isEligibleForRehire: companyPolicy
                ? "No, Company Policy"
                : isEligibleForRehire,
              companyPolicy,
              fullTime: workHour ? workHour === "fullTime" : undefined,
              ...Object.entries(workPlace || []).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {}
              ),
              ...restValues,
            }
          );
        }
        history.push("/employment-history-submitted");
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong, please try again.");
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [decodedTokenValues, history]
  );

  const decodeEmploymentHistoryToken = useCallback(async () => {
    try {
      setLoading(true);

      if (code) {
        const { driverId, employmentHistoryId }: DecodeTokenValues =
          jwtDecode(code);
        if (driverId && employmentHistoryId) {
          const {
            data: { driver },
          } = await api.driver.getDriverById(driverId);

          if (
            driver.employmentHistory?.find(
              (eh) => eh._id === employmentHistoryId
            )?.status === "Driver signed"
          ) {
            setDriver(driver);
            setDecodedTokenValues({
              driverId: driverId,
              employmentHistoryId: employmentHistoryId,
            });
          } else {
            history.push("/page-not-found");
          }
        }
      }
    } catch (err) {
      console.error(err);
      history.push("/page-not-found");
    } finally {
      setLoading(false);
    }
  }, [code, history]);

  const driverSignedUrl = useMemo(
    () =>
      driver?.employmentHistory?.find(
        (eh) => eh._id === decodedTokenValues?.employmentHistoryId
      )?.driverSignedUrl,
    [decodedTokenValues?.employmentHistoryId, driver?.employmentHistory]
  );

  const validators = useMemo(
    () => ({
      required: general.undefined(Translations["error-required"]),
    }),
    []
  );

  const reasonForTestPrerequisites = useMemo(
    () => [
      hadBreathAlcoholTest,
      hadPositiveDrugTest,
      hasRefusedControlledSubstanceTest,
      hasViolatedDOT,
      previousEmployerDrugAndAlcohol,
    ],
    [
      hadBreathAlcoholTest,
      hadPositiveDrugTest,
      hasRefusedControlledSubstanceTest,
      hasViolatedDOT,
      previousEmployerDrugAndAlcohol,
    ]
  );

  const isReasonsForTestVisible = useMemo(
    () => reasonForTestPrerequisites.some(Boolean),
    [reasonForTestPrerequisites]
  );

  useEffect(() => {
    decodeEmploymentHistoryToken();
  }, [decodeEmploymentHistoryToken]);

  useEffect(() => {
    if (noAccidents) {
      dispatch(
        change("EmploymentHistoryForm", "motorVehicleAccidents", undefined)
      );
    }
  }, [dispatch, noAccidents]);

  useEffect(() => {
    if (!isReasonsForTestVisible) {
      ["reasonForTest", "resultOfTest", "dateOfTest"].forEach((fieldName) =>
        dispatch(change("EmploymentHistoryForm", fieldName, undefined))
      );
    }
  }, [dispatch, isReasonsForTestVisible]);

  useEffect(() => {
    if (isEligibleForRehire && isEligibleForRehire !== "No") {
      dispatch(change("EmploymentHistoryForm", "companyPolicy", undefined));
    }
  }, [dispatch, isEligibleForRehire]);

  if (loading || !driver || !driverSignedUrl) {
    return (
      <div className="seh-spinner-wrapper">
        <ClipLoader size={150} color="#011077" />
        <label className="seh-spinner-msg">{Translations["please-wait"]}</label>
      </div>
    );
  }

  return (
    <div className="seh-form-template seh-app employment-history-form">
      <h2 className="employment-history-form__header">
        {Translations["employment-history-signing"]}
      </h2>
      <p
        className="employment-history-form__fill-the-form"
        onClick={() =>
          document.querySelector("form")?.scrollIntoView({ behavior: "smooth" })
        }
      >
        {Translations["fill-the-form"]}
      </p>
      <p className="employment-history-form__preview-document">
        If the document is not shown properly, click{" "}
        <a target="_blank" rel="noopener noreferrer" href={driverSignedUrl}>
          here
        </a>{" "}
        to preview the document.
      </p>
      <div className="employment-history-form__container">
        <iframe
          className="employment-history-form__pdf-file"
          title={Translations["employment-history"]}
          src={driverSignedUrl}
          height="100%"
          width="100%"
        />
      </div>
      <Form
        onSubmit={handleSubmit((values) =>
          handleEmploymentHistorySubmit(values)
        )}
      >
        <div className="seh-field-array__item__info__main">
          <Field
            validate={[
              validators.required,
              globalValidators.onlyPast,
              globalValidators.dateBeforeFieldValidation(
                Translations["error-before-field-validation"],
                "employmentDateTo"
              ),
            ]}
            name="employmentDateFrom"
            component={ReduxDatepicker}
            formName="EmploymentHistoryForm"
            label={Translations["date-from"]}
            required
            className="seh-field-array__item__info__main__field employment-history-form__divider"
          />
          <Field
            validate={[
              validators.required,
              globalValidators.onlyPast,
              globalValidators.dateAfterFieldValidation(
                Translations["error-after-field-validation"],
                "employmentDateFrom"
              ),
            ]}
            name="employmentDateTo"
            component={ReduxDatepicker}
            formName="EmploymentHistoryForm"
            label={Translations["date-to"]}
            required
            className="seh-field-array__item__info__main__field employment-history-form__divider"
          />
        </div>
        <Field
          name="workHour"
          component={ReduxRadioGroup}
          options={workHour}
          label={Translations["work-hour"]}
          className="seh-field-array__item__info__main__field employment-history-form__divider"
        />
        <Field
          validate={validators.required}
          name="positionHeld"
          required
          component={ReduxInput}
          label={Translations["position-held"]}
          className="employment-history-form__divider"
        />
        <CheckBoxGroup
          name="workPlace"
          options={workPlace}
          required
          label={Translations["work-place"]}
          className="employment-history-form__divider"
        />
        <Field
          name="operatedVehiclesGt26k"
          component={ReduxToggleButton}
          label={Translations["did-driver-operate-gvwr"]}
          className="seh-field-array__item__info__main__field employment-history-form__divider"
        />
        <CheckBoxGroup
          name="typeOfEquipmentOperated"
          options={typeOfEquipmentOperated}
          label={Translations["type-of-equipment-operated"]}
          required
          className="employment-history-form__checkbox-group"
        />
        <CheckBoxGroup
          name="reasonForLeaving"
          options={reasonForLeaving}
          label="Reason for leaving"
          required
          className="employment-history-form__checkbox-group"
        />
        <Field
          name="ifTerminatedWhy"
          component={ReduxInput}
          label={Translations["if-terminated"]}
          className="employment-history-form__divider"
        />
        <Field
          name="isEligibleForRehire"
          component={ReduxRadioGroup}
          options={isEligibleForRehireOptions}
          label={Translations["eligible-for-rehire"]}
        />
        {isEligibleForRehire === "No" && (
          <Field
            name="companyPolicy"
            component={ReduxInput}
            label={Translations["company-policy"]}
            className="employment-history-form__divider"
          />
        )}
        <h3 className="employment-history-form__sub-header">
          {Translations["motor-vehicle-header"]}
        </h3>
        <Field
          name="noAccidents"
          component={ReduxCheckbox}
          label={Translations["if-no-accidents"]}
          className="seh-field-array__item__info__main__field employment-history-form__divider"
        />
        {!noAccidents && (
          <Fields
            name="motorVehicleAccidents"
            component={MotorVehicleAccidents}
          />
        )}
        <div>
          <h3 className="employment-history-form__sub-header">
            {Translations["alchocol-and-controll"]}
          </h3>
          <Field
            name="hadBreathAlcoholTest"
            component={ReduxToggleButton}
            label={Translations["breath-alcohol-test"]}
            className="employment-history-form__divider"
          />
          <Field
            name="hadPositiveDrugTest"
            component={ReduxToggleButton}
            label={Translations["positive-drug-test"]}
            className="employment-history-form__divider"
          />
          <Field
            name="hasRefusedControlledSubstanceTest"
            component={ReduxToggleButton}
            label={Translations["controlled-substance-test"]}
            className="employment-history-form__divider"
          />
          <Field
            name="hasViolatedDOT"
            component={ReduxToggleButton}
            label={Translations["violeted-any-dot-drug"]}
            className="employment-history-form__divider"
          />
          <Field
            name="previousEmployerDrugAndAlcohol"
            component={ReduxToggleButton}
            label={Translations["violeted-any-dot-drug-previus-employer"]}
            className="employment-history-form__divider"
          />

          {isReasonsForTestVisible && (
            <div className="seh-field-array__item__info__main">
              <Field
                name="reasonForTest"
                validate={validators.required}
                required
                component={ReduxInput}
                label={Translations["reason-for-test"]}
                className="seh-field-array__item__info__main__field employment-history-form__divider "
              />
              <Field
                name="resultOfTest"
                validate={validators.required}
                component={ReduxInput}
                required
                label={Translations["result-of-test"]}
                className="seh-field-array__item__info__main__field employment-history-form__divider"
              />
              <Field
                name="dateOfTest"
                validate={[validators.required, globalValidators.onlyPast]}
                component={ReduxDatepicker}
                required
                formName="EmploymentHistoryForm"
                label={Translations["date-of-test"]}
                className="seh-field-array__item__info__main__field employment-history-form__divider"
              />
            </div>
          )}
          <Field
            name="ifTestPositive"
            component={ReduxToggleButton}
            label={Translations["if-tested-positive"]}
            className="employment-history-form__divider"
          />
          <Field
            name="anyOtherRemarks"
            component={ReduxInput}
            label={Translations["any-other-remarks"]}
            className="employment-history-form__divider"
          />
          <div className="seh-field-array__item__info__main">
            <Field
              name="verificationCompletedBy"
              validate={validators.required}
              component={ReduxInput}
              required
              label={Translations["verification-completed-by"]}
              className="seh-field-array__item__info__main__field employment-history-form__divider"
            />
            <Field
              name="title"
              validate={validators.required}
              component={ReduxInput}
              required
              label={Translations["title"]}
              className="seh-field-array__item__info__main__field employment-history-form__divider"
            />
          </div>
          <div className="seh-field-array__item__info__main">
            <Field
              name="phoneNumber"
              component={ReduxInput}
              validate={[
                validators.required,
                numberFieldValidation.exactDigits(
                  Translations["error-phone-number"],
                  10
                ),
              ]}
              type="number"
              label={Translations["phone-number"]}
              required
              className="seh-field-array__item__info__main__field employment-history-form__divider"
            />
            <Field
              name="verificationDate"
              validate={[validators.required, globalValidators.onlyPast]}
              component={ReduxDatepicker}
              formName="EmploymentHistoryForm"
              label={Translations["verification-date"]}
              required
              className="seh-field-array__item__info__main__field employment-history-form__divider"
            />
          </div>
        </div>
        <div className="rating-form__button-box">
          <Button className="rating-form__button">
            {Translations["submit"]}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: "EmploymentHistoryForm",
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(EmploymentHistoryForm);
