import React from "react";
import classNames from "classnames";
import { WrappedFieldProps } from "redux-form";

type ReduxCheckboxProps = {
  className?: string;
  label: string;
} & WrappedFieldProps;

const ReduxCheckbox: React.FC<ReduxCheckboxProps> = (props) => {
  const {
    className,
    label,
    input,
    meta: { error },
    ...rest
  } = props;

  const classes = classNames("seh-checkbox", className);

  return (
    <div className={classes}>
      <label className="seh-checkbox__label">{label}</label>
      <input
        {...rest}
        {...input}
        type="checkbox"
        className="seh-checkbox__input"
      />
      {error && <small>{error}</small>}
    </div>
  );
};

export default ReduxCheckbox;
