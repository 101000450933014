import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { WrappedFieldProps } from "redux-form";

import "../ReduxCheckbox/ReduxCheckbox.styles.scss";

type CheckboxProps = {
  className?: string;
  label: string;
  options?: string[];
  isAnyCheckboxTouched: boolean;
  setIsAnyCheckboxTouched: (value: boolean) => void;
} & WrappedFieldProps;

const ReduxCheckboxFieldGroupRelated: React.FC<CheckboxProps> = (props) => {
  const {
    className,
    label,
    input: { onChange, ...restInput },
    meta,
    isAnyCheckboxTouched,
    setIsAnyCheckboxTouched,
    ...rest
  } = props;

  const classes = classNames("seh-checkbox", className);

  useEffect(() => {
    onChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meta.touched && !isAnyCheckboxTouched) {
      setIsAnyCheckboxTouched(true);
    }
  }, [isAnyCheckboxTouched, meta.touched, setIsAnyCheckboxTouched]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );
  return (
    <div className={classes}>
      <label htmlFor={restInput.name} className="seh-checkbox__label">
        {label}
      </label>
      <input
        {...rest}
        {...restInput}
        checked={Boolean(restInput.value)}
        id={restInput.name}
        type="checkbox"
        onChange={handleOnChange}
        className={"seh-checkbox__input"}
      />
    </div>
  );
};

export default ReduxCheckboxFieldGroupRelated;
