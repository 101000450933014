import { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import ReduxInput from '../../components/redux-ui/ReduxInput';
import Button from '../../components/ui/Button/Button';
import Fields from '../../components/redux-ui/FieldArrayComponents/Fields/Fields.component';
import AnnualReview from '../../components/redux-ui/FieldArrayComponents/AnnualReview/AnnualReview';
import Translations from '../../translations/translations.json';
import '../../index.scss';
import statesOptions from '../../utils/statesOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useFormValue from '../../hooks/useFormValue';
import { Input } from '../../components/ui';
import { scrollToFirstValidationError } from '../../utils/scrollToFirstValidationError';

const Step6 = (props: any) => {
  const { handleSubmit, onPreviousPage, change } = props;

  useEffect(() => {
    change('typeOfPowerUnit', 'Semi-truck');
    change('typeOfTrailer', 'Dry van');
  }, [change]);

  const firstName = useFormValue('firstName');
  const middleName = useFormValue('middleName');
  const lastName = useFormValue('lastName');
  const primaryDriverLicence = useFormValue('primaryDriverLicence');
  const hasLessThan2YearsOfExperience = useFormValue(
    'hasLessThan2YearsOfExperience'
  );

  const renderState = statesOptions.find(
    (el) => el.value === primaryDriverLicence.state
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <div className="seh-form-container__content">
          {hasLessThan2YearsOfExperience && false && ( // remove false if they want this on form
            <>
              <h2>{Translations['certification-of-road-test']}</h2>
              <div className="seh-info-container">
                <p className="seh-info">{Translations['step-7-info-1']}</p>
              </div>
              <div className="seh-form-container__content__group">
                <Input
                  label={Translations['drivers-name']}
                  type="text"
                  className="seh-form-container__content__group__field"
                  value={`${firstName} ${middleName} ${lastName}`}
                  readOnly
                />
                <Field
                  name="ssn"
                  label={Translations['ssn']}
                  type="text"
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  readOnly
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="primaryDriverLicence.licenceNumber"
                  label={Translations['licence-number']}
                  type="text"
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  readOnly
                />
                <Input
                  label={Translations['state']}
                  className="seh-form-container__content__group__field"
                  value={renderState?.label}
                  readOnly
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="typeOfPowerUnit"
                  label={Translations['type-of-power-unit']}
                  type="text"
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  readOnly
                />
                <Field
                  name="typeOfTrailer"
                  label={Translations['type-of-trailer']}
                  type="text"
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  readOnly
                />
              </div>
            </>
          )}
          <Fields name="violations" component={AnnualReview} />
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations['previous-page']}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations['next-page']}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'SEForm',
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step6);
