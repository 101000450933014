import React from 'react';
import classNames from 'classnames';
import './ActionButton.scss';
import { DefaultButtonProps } from '../Button/Button';

export type ActionButtonProps = {
  action: 'add' | 'remove';
} & DefaultButtonProps;

const ActionButton: React.FC<ActionButtonProps> = (
  props: ActionButtonProps
) => {
  const { className, children, disabled, action, ...rest } = props;
  return (
    <button
      type="button"
      className={classNames(
        'seh-action-button',
        { 'seh-action-button--disabled': disabled },
        { 'seh-action-button--remove': action === 'remove' },
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default ActionButton;
