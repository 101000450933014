import { triggerAsyncId } from 'async_hooks'
import { RefObject, useEffect } from 'react'

type Params = {
  dropdownRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
  triggerRef: RefObject<HTMLDivElement>
  open: boolean
  setUpwards: (param: boolean) => void
  setBackwards: (param: boolean) => void
  renderAsPortal: boolean
  containerNotRelative?: boolean
}
const usePositioning = ({
  dropdownRef,
  contentRef,
  containerNotRelative,
  triggerRef,
  open,
  setUpwards,
  setBackwards,
  renderAsPortal,
}: Params) => {
  useEffect(() => {
    const getWindowDimensions = () => {
      const screenHeight = window.innerHeight
      const screenWidth = window.innerWidth
      return {
        screenHeight,
        screenWidth,
      }
    }

    const positionContent = (screenWidth: number, screenHeight: number) => {
      const offset = 10

      const {
        width: contentWidth,
        height: contentHeight,
      } = contentRef.current!.getBoundingClientRect()
      const {
        width: triggerWidth,
        height: triggerHeight,
      } = triggerRef.current!.getBoundingClientRect()
      const {
        top: dropdownTopOffset,
        left: dropdownLeftOffset,
      } = dropdownRef.current!.getBoundingClientRect()
      let topOffset = !renderAsPortal ? 0 : dropdownTopOffset + window.scrollY
      if (dropdownTopOffset + contentHeight + offset > screenHeight) {
        setUpwards(true)
      } else {
        setUpwards(false)
        !containerNotRelative &&
          (topOffset = !renderAsPortal
            ? triggerHeight
            : topOffset + triggerHeight)
      }
      let leftOffset = !renderAsPortal ? 0 : dropdownLeftOffset + window.scrollX

      if (dropdownLeftOffset + contentWidth + offset > screenWidth) {
        setBackwards(true)
        leftOffset += triggerWidth
      } else {
        setBackwards(false)
      }
      contentRef.current!.style.top = `${topOffset}px`
      contentRef.current!.style.left = `${leftOffset}px`
      contentRef.current!.style.visibility = 'visible'
    }
    if (open) {
      const { screenHeight, screenWidth } = getWindowDimensions()
      positionContent(screenWidth, screenHeight)
    } else {
      contentRef.current!.style.visibility = 'hidden'
    }
  }, [
    open,
    dropdownRef,
    contentRef,
    triggerRef,
    setUpwards,
    setBackwards,
    renderAsPortal,
  ])
}
export default usePositioning
