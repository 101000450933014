import Translations from '../../../../../translations/translations.json';

export const selectOptions = [
  {
    value: 'Van',
    label: Translations['van'],
  },
  {
    value: 'Tank',
    label: Translations['tank'],
  },
  {
    value: 'Flat',
    label: Translations['flat'],
  },
  {
    value: 'Dump',
    label: Translations['dump'],
  },
  {
    value: 'Refer',
    label: Translations['refer'],
  },
];
