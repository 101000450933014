import React from 'react'
import RadioGroup, {
  RadioGroupProps,
} from '../ui/RadioGroup/RadioGroup.component'
import { WrappedFieldProps } from 'redux-form'

type ReduxRadioGroupProps = WrappedFieldProps & RadioGroupProps
const ReduxRadioGroup: React.FC<ReduxRadioGroupProps> = ({
  input,
  meta: { touched, error },
  onChangeHandle,
  ...rest
}) => {
  return (
    <RadioGroup
      onChangeHandle={({ value }: { value: string }) => {
        input.onChange(value)
      }}
      {...input}
      {...rest}
      {...(touched && { error })}
    />
  )
}

export default ReduxRadioGroup
