import { DateTime } from 'luxon'
import { createContext, ReactNode } from 'react'
import { PickersType } from '../picker'

type ContextValues = {
  date?: DateTime
  minDate?: DateTime
  maxDate?: DateTime
  onChange?: (date: DateTime) => void
  close?: () => void

  headerContent?: ReactNode | ReactNode[]
  setHeaderContent?: React.Dispatch<
    React.SetStateAction<ReactNode | ReactNode[]>
  >
  showInMenu?: PickersType
  setShowInMenu?: React.Dispatch<React.SetStateAction<PickersType>>
  currentDate?: DateTime
  setCurrentDate?: React.Dispatch<React.SetStateAction<DateTime>>
  yearFrom?: number
  setYearFrom?: React.Dispatch<React.SetStateAction<number>>
}

export default createContext<ContextValues>({})
