import React from "react";

type RadioSVGProps = {
  className?: string;
  color?: string;
  onClick?: () => void;
};

const RadioSVG: React.FC<RadioSVGProps> = (props) => {
  const { className, color = "#011077", onClick } = props;

  return (
    <svg
      onClick={onClick}
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <circle cx="9" cy="9" r="7" strokeWidth="2" stroke={color} fill="none" />
      <circle className="inner" fill={color} cx="9" cy="9" r="4" />
    </svg>
  );
};

export default RadioSVG;
