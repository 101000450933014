import httpClient from "../api/httpClient";
import { store } from "../store";

const initial_data = {};

export class PatchService {
  static lastPatchObj = null;

  static async patch() {
    await new Promise((resolve) =>
      setTimeout(async () => {
        const state = store.getState();
        const { currentPage, stepsCompleted } = state;
        if (
          state.form &&
          state.form["SEForm"] &&
          state.form["SEForm"].values &&
          state.form["SEForm"].values !== initial_data &&
          this.token &&
          this.token !== "tmp"
        ) {
          const values = state.form["SEForm"].values;

          //TODO: ask bekend for _id in created tmp object
          // const { _id: id, ...otherProps } = values;
          // const newObj = { id, ...otherProps };

          const getUniqueStepsCompleted = (value, index, self) => {
            return self.indexOf(value) === index;
          };
          const uniqueStepsCompleted = stepsCompleted
            .filter(getUniqueStepsCompleted)
            .sort();

          const patchData = {
            ...values,
            currentPage,
            stepsCompleted: uniqueStepsCompleted,
          };

          if (patchData !== this.lastPatchObj) {
            await httpClient.patch(`form/drivers/${this.token}`, {
              ...patchData,
            });
            resolve();
            // store.dispatch(patchApplicant({ finished: false }))
            this.lastPatchObj = patchData;
          }
        }
      }, 0)
    );
  }

  static set token(token) {
    localStorage.setItem("sehToken", token);
  }

  static get token() {
    return localStorage.getItem("sehToken");
  }

  /**
   * If you comment this line while testing please don't push it to git!
   */
  static clearToken() {
    localStorage.removeItem("sehToken");
  }

  static get tokenValid() {
    return this.token && this.token !== "tmp";
  }
}
