import { AxiosResponse } from "axios";
import { Review, ReviewRequest } from "../../types";
import httpClient from "../httpClient";

const verifyReviewToken = async (token: string) =>
  (await httpClient.get("/reviews/verify", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })) as AxiosResponse<ReviewRequest>;

const createReview = (reviews: Review[], token: string) =>
  httpClient.post(
    "/reviews",
    { reviews },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// eslint-disable-next-line import/no-anonymous-default-export
export default { verifyReviewToken, createReview };
