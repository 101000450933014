import { useEffect, useMemo, useRef, useState } from "react";
import {
  load,
  nextPage,
  jumpToPage,
  saveStepsCompleted,
  saveFetchedCompletedSteps,
  uncompleteStep,
} from "../store/actions";
import Step1 from "../pages/Step1/Step1";
import Step2 from "../pages/Step2/Step2";
import Step3 from "../pages/Step3/Step3";
import Step4 from "../pages/Step4/Step4";
import Step5 from "../pages/Step5/Step5";
import Step6 from "../pages/Step6/Step6";
import Step7 from "../pages/Step7/Step7";
import "../index.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import FormProgression from "./redux-ui/FormProgression/FormProgression";
import { isInvalid, reduxForm } from "redux-form";
import Translations from "../translations/translations.json";
import useQueryParams from "../hooks/useQueryParams";
import api from "../api";
import { PatchService } from "../services/patchService";
import { Footer } from "./ui/Footer";
import SubmitionPage from "../pages/SubmitionPage/SubmitionPage";
import classNames from "classnames";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ClipLoader from "react-spinners/ClipLoader";
import Loader from "react-spinners/PulseLoader";
import STATUS_ENUM from "../utils/statusEnums";
import { toast } from "react-toastify";

export type SEFromProps = {
  onSubmit: () => {};
};

export type PageType = {
  page?: JSX.Element;
  name?: string;
  key?: string;
};
type companyInfoType = {
  name?: string;
  ownersName?: string;
  photoUrl?: string;
  email?: string;
  description?: string;
};
export const PAGES: any[] = [
  { Page: Step1, name: "Applicant Information", key: "first" },
  { Page: Step2, name: "step2", key: "second" },
  { Page: Step3, name: "step3", key: "third" },
  { Page: Step4, name: "step4", key: "fourth" },
  { Page: Step5, name: "step5", key: "fifth" },
  { Page: Step6, name: "step6", key: "sixth" },
  { Page: Step7, name: "step7", key: "eighth" },
  { Page: SubmitionPage, name: "submitionPage", key: "submitionPage" },
];

const color = "#011077";

const SEForm = (props: any) => {
  const { invalid } = props;
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const currentPage = useSelector((state: any) => state.currentPage);
  const stepsCompleted: string[] = useSelector(
    (state: any) => state.stepsCompleted
  );
  const [companyInfo, setCompanyinfo] = useState<companyInfoType>();
  const { params } = useQueryParams<{ token: string }>();

  useEffect(() => {
    const loadDriver = () => {
      setLoading(true);
      api.patchService
        .getDriverByToken(params.token)
        .then((response) => {
          const {
            data: { driver },
          } = response;
          if (driver) {
            dispatch(load(driver));
            const { stepsCompleted, companyInfo, status } = driver;
            dispatch(saveFetchedCompletedSteps(stepsCompleted));
            if (status === STATUS_ENUM.PENDING) {
              if (!(stepsCompleted as string[]).includes("1"))
                dispatch(jumpToPage(1));
              else {
                dispatch(
                  jumpToPage(
                    ((stepsCompleted as string[])
                      .map(Number)
                      .find(
                        (step, ind, arr) =>
                          ind === arr.length - 1 || step + 1 !== arr[ind + 1]
                      ) || 0) + 1
                  )
                );
              }
            } else {
              dispatch(jumpToPage(1));
            }
            setCompanyinfo(companyInfo);
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          setIsInvalidToken(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    loadDriver();

    PatchService.token = params.token;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleSubmit = async (values: any) => {
    if (currentPage === PAGES.length - 1) {
      return api.patchService
        .submitForm(params.token, values)
        .then(() => {
          dispatch(nextPage());
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          toast.error(Translations["error-submittion-failed"]);
        });
    } else {
      try {
        await PatchService.patch();
        dispatch(saveStepsCompleted(currentPage.toString()));
        dispatch(nextPage());
        window.scrollTo(0, 0);
      } catch (er) {
        console.error(er);
      }
    }
  };

  const formClasses = classNames({
    "seh-form-template": true,
    "seh-form-template--submition": currentPage === PAGES.length,
  });

  const renderPages = () => {
    return PAGES.map((page, index) => {
      const { Page } = page;

      return (
        currentPage === index + 1 && (
          <div className={formClasses}>
            {props.submitting && (
              <div className="seh-loader">
                <Loader size={25} color={color} />
              </div>
            )}
            <Page
              onSubmit={handleSubmit}
              onPreviousPage={() => {
                if (currentPage > 1) {
                  dispatch(jumpToPage(currentPage - 1));
                  window.scrollTo(0, 0);
                }
              }}
            />
          </div>
        )
      );
    });
  };

  const stepsCompletedKey = useMemo(
    () => stepsCompleted.join("-"),
    [stepsCompleted]
  );

  const first = useRef(true);
  const lastStep = useRef(-1);

  useEffect(() => {
    if (lastStep.current !== currentPage) {
      return () => {
        lastStep.current = currentPage;
      };
    }
    if (stepsCompleted.includes(currentPage.toString()) && invalid) {
      dispatch(uncompleteStep(currentPage.toString()));
    }

    return () => {
      lastStep.current = currentPage;
    };
  }, [currentPage, dispatch, invalid, stepsCompleted]);

  useEffect(() => {
    if (!stepsCompletedKey) return;
    if (first.current) {
      first.current = false;
      return;
    }
    PatchService.patch();
  }, [stepsCompletedKey]);

  if (loading) {
    return (
      <div className="seh-spinner-wrapper">
        <ClipLoader size={150} color={color} />
        <label className="seh-spinner-msg">{Translations["please-wait"]}</label>
      </div>
    );
  }
  if (isInvalidToken) {
    return <ErrorPage />;
  }
  return (
    <div className="app-container">
      <div className="seh-app">
        <FormProgression
          labelName={"Step"}
          stages={[
            { name: Translations["applicant-information"] },
            { name: Translations["address-information"] },
            { name: Translations["employment-history-and-experience"] },
            { name: Translations["driver-information"] },
            { name: Translations["drivers-licence"] },
            { name: Translations["certification"] },
            { name: Translations["terms-and-conditions"] },
          ]}
          currentStage={currentPage}
        />
        {renderPages()}
      </div>
      {companyInfo && (
        <Footer
          companyName={companyInfo?.name}
          ceoName={companyInfo?.ownersName}
          email={companyInfo?.email}
          photoUrl={companyInfo?.photoUrl}
          description={companyInfo?.description}
        />
      )}
    </div>
  );
};

const form = reduxForm({
  form: "SEForm",
})(SEForm);

export default connect(
  (state: any) => ({
    initialValues: state.initialValues.data,
    invalid: isInvalid("SEForm")(state),
  }),
  {}
)(form);
