import React, { useState, useRef, useMemo, useEffect } from 'react'
import { Dropdown } from '../'
import { DropdownRef } from '../Dropdown/Dropdown.component'
import Arrow from './svgs/arrow'
import classNames from 'classnames'
import '../Input/Input.scss'
import './Select.scss'
import '../../../index.scss'

type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export type selectOptionType = {
  label: string
  value: string
}
export type SelectProps = {
  options: selectOptionType[]
  label?: string
  input?: DefaultInputProps
  onSelect?: ({ value }: { value: string }) => void
  required?: boolean
  placeholder?: string
  error?: string
  className?: string
  defaultValue?: selectOptionType
}
const Select = ({
  options,
  label,
  input,
  onSelect,
  required,
  placeholder,
  error,
  className,
  defaultValue,
}: SelectProps) => {
  const [value, setValue] = useState<selectOptionType>()
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef<DropdownRef>(null)
  const close = () => {
    dropdownRef.current?.close()
  }

  useEffect(() => {
    if (value) {
      onSelect?.({ value: value.value })
      close()
    }
  }, [value])

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    const result = options.find((opt) => opt.value === input?.value)
    setValue(result)
  }, [input?.value, options])

  const triggerContainerClassNames = classNames(
    'select__trigger-container',
    'seh-container__input',
    {
      'select__trigger-container--error__input': error,
      'select__trigger-container--error': error,
    },
  )
  const triggerClassNames = classNames(
    'input',
    'select__trigger-container__trigger',
    { 'as-placeholder': !value },
  )
  const arrowClassNames = classNames(
    'select__trigger-container__svg',
    {
      'select__trigger-container__svg__rotated': open,
    },
    { 'select__trigger-container__svg--error': error },
  )
  const trigger = useMemo(
    () => (
      <div className={triggerContainerClassNames}>
        <div className={triggerClassNames}>
          {value ? value.label : placeholder ? placeholder : options[0].label}
        </div>
        <Arrow className={arrowClassNames} />
      </div>
    ),
    [
      value,
      open,
      input,
      error,
      triggerContainerClassNames,
      triggerClassNames,
      arrowClassNames,
    ],
  )
  return (
    <div tabIndex={0} className={classNames('seh-container', className)}>
      {label && (
        <div className="label-container">
          <label
            className={classNames({ 'label-container__label--error': error })}
          >
            {label}
            {required && (
              <span className="label-container__label__star-required">*</span>
            )}
          </label>
        </div>
      )}
      <input {...input} type="hidden" required={required} />
      <Dropdown
        contentStickToTrigger
        contentFullWidth
        ref={dropdownRef}
        trigger={trigger}
        isClosableOnTrigger
        onChange={() => setOpen(dropdownRef.current?.isOpen!)}
      >
        <ul className="select__option-list">
          {options.map((el) => (
            <li
              key={el.value}
              className="select__option-list__option"
              onClick={() => {
                setValue(el)
              }}
              seh-container__input
            >
              {el.label}
            </li>
          ))}
        </ul>
      </Dropdown>
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  )
}
export default Select
