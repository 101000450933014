import React, { useState } from "react";
import StarButton from "../StarButton";
import { WrappedFieldProps } from "redux-form";

import "./StarRatingInput.styles.scss";
import classNames from "classnames";

type StarRatingInputProps = {
  numberOfStars?: number;
  className?: string;
} & WrappedFieldProps;

const StarRatingInput: React.FC<StarRatingInputProps> = (props) => {
  const {
    numberOfStars = 5,
    input: { onChange },
    meta: { touched, error },
    className,
  } = props;
  const [selectedStarIndex, setSelectedStarIndex] = useState<number>(0);

  const handleClick = (index: number) => {
    onChange(index);
    setSelectedStarIndex(index);
  };

  const classes = classNames("star-rating-input", className);

  return (
    <div className={classes}>
      <div className="star-rating-input__stars-box">
        {[...Array(numberOfStars)].map((el, index) => {
          return (
            <StarButton
              isActive={!!selectedStarIndex && index <= selectedStarIndex - 1}
              onClick={() => handleClick(index + 1)}
              className={touched && error && "star-rating-input--error"}
            />
          );
        })}
      </div>
      {touched && error && (
        <small className="star-rating-input__error-message">{error}</small>
      )}
    </div>
  );
};
export default StarRatingInput;
