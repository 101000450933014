import classNames from 'classnames';
import React from 'react';
import './Textarea.scss';

type DefaultTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type TextareaProps = {
  label?: string;
  error?: string;
} & DefaultTextareaProps;

const Textarea: React.FC<TextareaProps> = (props: TextareaProps) => {
  const { label, required, error, disabled, className, ...rest } = props;

  return (
    <div
      className={classNames(
        'seh-container',
        { 'seh-container--error': error },
        { 'seh-container--disabled': disabled },
        className
      )}
    >
      {label && (
        <div>
          <label>
            {label}
            {required && <span className="seh-required">*</span>}
          </label>
        </div>
      )}
      <textarea
        disabled={disabled}
        required={required}
        spellCheck={false}
        className={classNames(
          'seh-container__input',
          { 'seh-container--error__input': error },
          { 'seh-container--disabled__input': disabled }
        )}
        {...rest}
      />
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  );
};

export default Textarea;
