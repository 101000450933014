import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import ReduxInput from '../../components/redux-ui/ReduxInput';
import Button from '../../components/ui/Button/Button';
import ReduxDatepickerArray from '../../components/redux-ui/FieldArrayComponents/ReduxDatepickerArray/ReduxDatepickerArray';
import Translations from '../../translations/translations.json';
import Fields from '../../components/redux-ui/FieldArrayComponents/Fields/Fields.component';
import { useDispatch } from 'react-redux';
import useFormValue from '../../hooks/useFormValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../components/ui';
import { formatDate } from '../../utils/formatDate';
import statesOptions from '../../utils/statesOptions';
import { general, numberFieldValidation } from '../../validators';
import ReduxDatepicker from '../../components/redux-ui/ReduxDatepicker';
import { globalValidators } from '../../utils';
import { scrollToFirstValidationError } from '../../utils/scrollToFirstValidationError';

const Step4 = (props: any) => {
  const { handleSubmit, onPreviousPage } = props;

  const dutyHours = useFormValue('onDutyHours');
  const firstName = useFormValue('firstName');
  const middleName = useFormValue('middleName');
  const lastName = useFormValue('lastName');
  const primaryDriverLicence = useFormValue('primaryDriverLicence');
  const companyInfo = useFormValue('companyInfo');

  const dispatch = useDispatch();

  const renderDate = formatDate(primaryDriverLicence.expirationDate);
  const renderState = statesOptions.find(
    (el) => el.value === primaryDriverLicence.state
  );

  useEffect(() => {
    if (!dutyHours) {
      return;
    }
    const totalHoursWorked = dutyHours.reduce(
      (sum: number, el: Record<string, any>) => {
        if (el.hoursWorked) {
          return (sum += +el.hoursWorked);
        }
        return sum;
      },
      0
    );
    dispatch(change('SEForm', 'totalHoursWorked', totalHoursWorked));
  }, [dutyHours, dispatch]);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations['error-required']),
      maxValue: numberFieldValidation.maxValue(
        Translations['error-total-hours-worked'],
        72
      ),
    }),
    []
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <h2>{Translations['driver-statement-of-on-duty-hours']}</h2>
        <div className="seh-info-container">
          <p className="seh-info">{Translations['step-4-info-1']}</p>
        </div>
        <div className="seh-form-container__content">
          <h2>{Translations['driver-information']}</h2>
          <div className="seh-form-container__content__group">
            <Input
              label={Translations['drivers-name']}
              className="seh-form-container__content__group__field"
              readOnly
              value={`${firstName} ${middleName} ${lastName}`}
            />
            <Field
              name="primaryDriverLicence.licenceNumber"
              label={Translations['drivers-licence-number']}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              readOnly
            />
          </div>
          <div className="seh-form-container__content__group">
            <Input
              label={Translations['expiration-date']}
              className="seh-form-container__content__group__field"
              readOnly
              value={renderDate}
            />
            <Field
              name="primaryDriverLicence.type"
              label={Translations['type']}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              readOnly
            />
            <Input
              label={Translations['state']}
              className="seh-form-container__content__group__field"
              readOnly
              value={renderState?.label}
            />
          </div>
        </div>
        <Fields name="onDutyHours" component={ReduxDatepickerArray} />
        <div className="seh-form-container__content">
          <div className="seh-form-container__content__group">
            <Field
              name="totalHoursWorked"
              label={Translations['total-hours-worked']}
              readOnly
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              validate={validations.maxValue}
            />
          </div>
        </div>
        <div className="seh-form-container__content">
          <div className="seh-form-container__content__group">
            <Field
              name="relievedFromWork"
              label={Translations['relieved-from-work-date']}
              component={ReduxDatepicker}
              className="seh-form-container__content__group__field"
              onlyPast
              required
              validate={[
                validations.undefined,
                globalValidators.onlyPast,
              ]}
            />
          </div>
        </div>
        <div className="seh-form-container__content">
          <h2>{Translations['carrier-information']}</h2>
          <div className="seh-form-container__content__group">
            <Input
              label={Translations['carrier-official']}
              className="seh-form-container__content__group__field"
              value={companyInfo?.ownersName}
              readOnly
            />
            <Input
              label={Translations['title']}
              className="seh-form-container__content__group__field"
              value={Translations['president']}
              readOnly
            />
          </div>
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations['previous-page']}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations['next-page']}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'SEForm',
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step4);
