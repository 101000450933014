import React from 'react';
import { FieldArray, WrappedFieldArrayProps } from 'redux-form';

type FieldsProps<FieldValue = any, T = {}> = {
  name: string;
  component: React.FC<T>;
} & Omit<T, keyof WrappedFieldArrayProps<FieldValue>>;

function Fields<FieldValue = any, T extends {} = any>(
  props: FieldsProps<FieldValue, T>
) {
  const { name, component, ...rest } = props;
  return <FieldArray<any> name={name} component={component} {...rest} />;
}

export default Fields;
