import Translations from "../../translations/translations.json";

const EmploymentHistoryFormSubmition = () => {
  return (
    <div className="submit-page">
      <div className="submit-page__content">
        <h2 className="submit-page__content__header">
          {Translations["thank-you"]}!
        </h2>
        <span className="submit-page__content__info">
          {Translations["employment-submitted-form-msg"]}
        </span>
      </div>
    </div>
  );
};

export default EmploymentHistoryFormSubmition;
