type OnDutyHours = {
  date: string; // e.g. '2021-04-27T00:00:00.000Z'
  hoursWorked?: number;
};

export const getUIDates = (dates: OnDutyHours[]): string[] => {
  const result = dates.map((el) => el.date.substring(0, 10)); // only date, e.g. '2021-04-27'

  // result need to be array of strings in format MM/DD/YYYY
  return result.map(
    (el) => el.slice(5, 7) + '/' + el.slice(8, 10) + '/' + el.slice(0, 4)
  );
};
