import React, { useContext } from 'react'
import './MonthPicker.scss'
import '../../DatepickerMenu.styles.scss'

import { DateTime } from 'luxon'
import { Pickers, MONTH_NAMES } from '../../utils'
import classNames from 'classnames'
import { isMonthValid, isYearValid, setValidMonthDay } from '../../../utils'
import MenuContext from '../../context'

const { DAY_PICKER } = Pickers

type MonthPickerProps = {
  className?: string
}

const MonthPicker: React.FC<MonthPickerProps> = ({ className }) => {
  const {
    date = DateTime.now(),
    minDate = DateTime.now().set({ year: 1900 }),
    maxDate = DateTime.now().set({ year: 2100 }),
    currentDate = DateTime.now(),
    setCurrentDate,
    setShowInMenu,
  } = useContext(MenuContext)

  const selectedFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--selected',
    'datepicker-menu-container__main__field--valid',
  )
  const validFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--valid',
  )
  const invalidFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--invalid',
  )
  const MonthPickerClassNames = classNames(
    'datepicker-menu-container__main',
    'month-picker',
    className,
  )

  return (
    <div className={MonthPickerClassNames}>
      {MONTH_NAMES.map((month, index) => (
        <div
          className={
            index + 1 === date.month && date.year === currentDate.year
              ? selectedFieldClassNames
              : isYearValid(currentDate.year, minDate, maxDate) &&
                isMonthValid(index + 1, currentDate, minDate, maxDate)
              ? validFieldClassNames
              : invalidFieldClassNames
          }
          onClick={() => {
            if (isMonthValid(index + 1, currentDate, minDate, maxDate)) {
              setCurrentDate?.((oldVal) => setValidMonthDay(oldVal, index + 1))
              setShowInMenu?.(DAY_PICKER)
            }
          }}
        >
          {month}
        </div>
      ))}
    </div>
  )
}

export default MonthPicker
