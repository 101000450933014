import React, { useCallback, useMemo } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import { general } from "../../../../validators";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import CheckBoxGroup from "../../ReduxCheckBoxGroup";
import ReduxDatepicker from "../../ReduxDatepicker";
import ReduxInput from "../../ReduxInput";
import Translations from "../../../../translations/translations.json";
import { globalValidators } from "../../../../utils";

import "./MotorVehicleAccidents.styles.scss";

const motorVehiclesAccidents = [
  { label: Translations["tow"], value: "tow", name: "tow" },
  { label: Translations["injury"], value: "injury", name: "injury" },
  { label: Translations["fatality"], value: "fatality", name: "fatality" },
  { label: Translations["hm-release"], value: "hmRelease", name: "hmRelease" },
];

type MotorVehicleAccidentsProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const MotorVehicleAccidents: React.FC<MotorVehicleAccidentsProps> = ({
  fields,
  maxEntries = 4,
}) => {
  useFieldsInit(fields);

  const validators = useMemo(
    () => ({
      required: general.undefined(Translations["error-required"]),
    }),
    []
  );

  const handleRemoveMotorVehicleAccident = useCallback(
    (index: number) => {
      requestAnimationFrame(() => fields.remove(index));
    },
    [fields]
  );

  return (
    <div className="seh-field-array">
      {fields.map((motorVehicleAccident, index) => (
        <React.Fragment key={motorVehicleAccident}>
          <div className="motor-vehicle-accidents__button-wrapper">
            {index > 0 && (
              <ActionButton
                action="remove"
                onClick={() => handleRemoveMotorVehicleAccident(index)}
                className="motor-vehicle-accidents__button-wrapper__button"
              >
                {Translations["remove"]}
              </ActionButton>
            )}
          </div>
          <div className="seh-field-array__item__info__main">
            <Field
              name={`${motorVehicleAccident}.accidentDate`}
              component={ReduxDatepicker}
              validate={[
                validators.required,
                globalValidators.onlyPast,
                globalValidators.dateIntervalValidation(
                  Translations["error-accident-date"],
                  "employmentDateFrom",
                  "employmentDateTo"
                ),
              ]}
              required
              formName="EmploymentHistoryForm"
              label={Translations["accident-date"]}
              className="seh-field-array__item__info__main__field"
            />
            <Field
              name={`${motorVehicleAccident}.city`}
              validate={validators.required}
              component={ReduxInput}
              required
              label={Translations["city-state"]}
              className="seh-field-array__item__info__main__field"
            />
          </div>
          <CheckBoxGroup
            name={motorVehicleAccident}
            options={motorVehiclesAccidents}
            indexInFieldArray={index}
            required
            label={Translations["did-accident-involve"]}
            className="employment-history-form__checkbox-group"
          />
          <Field
            name={`${motorVehicleAccident}.briefDescription`}
            component={ReduxInput}
            label={Translations["brief-description"]}
            className="motor-vehicle-accidents__brief-description"
          />
        </React.Fragment>
      ))}
      <div className="motor-vehicle-accidents__button-wrapper">
        <ActionButton
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => {
            fields.push({});
          }}
          className="motor-vehicle-accidents__button-wrapper__button"
        >
          {Translations["add-accident-data"]}
        </ActionButton>
      </div>
    </div>
  );
};

export default MotorVehicleAccidents;
