import React from 'react'
import './Footer.styles.scss'
import Translations from '../../../translations/translations.json'

type FooterProps = {
  companyName?: string
  email?: string
  photoUrl?: string
  ceoName?: string
  description?: string
}
const Footer: React.FC<FooterProps> = ({
  companyName,
  ceoName,
  email,
  photoUrl,
  description,
}) => {
  return (
    <footer className="footer-container">
      <div className="footer-container__content">
        {photoUrl && (
          <div className="footer-container__image-container">
            <img
              className="footer-container__image-container__image"
              alt={companyName}
              src={photoUrl}
            />
          </div>
        )}
        {companyName && (
          <div className="footer-container__content__segment">
            <h2 className="footer-container__content__segment__segment-header">
              {companyName}
            </h2>
            <p className="footer-container__content__segment__segment-description">
              {description}
            </p>
          </div>
        )}
        {(ceoName || email) && (
          <div className="footer-container__content__segment">
            <h2 className="footer-container__content__segment__segment-header">
              {Translations['contact']}
            </h2>
            <p className="footer-container__content__segment__email-label">
              {email}
            </p>
          </div>
        )}
      </div>
    </footer>
  )
}
export default Footer
