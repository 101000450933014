import { useMemo } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import ReduxSelect from '../../ReduxSelect';
import '../index.scss';
import general from '../../../../validators/general';
import Translations from '../../../../translations/translations.json';
import { selectOptions } from './utils';

type TruckInfoProps = {
  maxEntries?: number;
} & WrappedFieldProps;

const TruckInfo: React.FC<TruckInfoProps> = ({ input: { name } }) => {
  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations['error-required']),
    }),
    []
  );

  return (
    <div className="seh-field-array">
      <>
        <div className="seh-field-array__title">
          <label className="seh-field-array__title__label">
            {Translations['truck-info']}
          </label>
        </div>
        <div className="seh-field-array__item">
          <div className="seh-field-array__item__info">
            <div className="seh-field-array__item__info__main">
              <Field
                name={`${name}.equipmentType`}
                label={Translations['equipment-type']}
                className="seh-field-array__item__info__main__field"
                component={ReduxSelect}
                options={selectOptions}
                required
                validate={validations.undefined}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TruckInfo;
