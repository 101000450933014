import { selectOptionType } from '../../Select/Select.component';

export const transformValues = (
  value: string[],
  options: selectOptionType[]
) => {
  return value.map((value: string) => {
    const result = options.find(
      (option: selectOptionType) => option.value === value
    );
    if (result) {
      return result;
    } else {
      return null;
    }
  });
};
