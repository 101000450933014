import { DateTime } from 'luxon'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Dropdown } from '../'
import DatepickerMenu from './DatepickerMenu'
import DatepickerInput from './DatepickerInput'
import { DropdownRef } from '../Dropdown/Dropdown.component'
import classNames from 'classnames'
import { touch, focus } from 'redux-form'
import './Datepicker.styles.scss'
import '../../../index.scss'
import { useDispatch } from 'react-redux'

type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
type Never<T> = { [P in keyof T]?: never }
type XOR<T, U> = (Never<T> & U) | (Never<U> & T)

export type DatepickerMenuProps = XOR<
  XOR<FutureDatepickerMenuProps, PastDatepickerMenuProps>,
  FromToDatepickerMenuProps
>
type FutureDatepickerMenuProps = {
  onlyFuture?: boolean
}
type PastDatepickerMenuProps = {
  onlyPast?: boolean
}
type FromToDatepickerMenuProps = {
  minDate?: DateTime
  maxDate?: DateTime
}
export type DefaultDatepickerProps = {
  formName?: string;
  label?: string
  value?: string
  required?: boolean
  placeholder?: string
  className?: string
  error?: string
} & DefaultInputProps
export type DatepickerProps = DefaultDatepickerProps & DatepickerMenuProps
const Datepicker = (props: DatepickerProps): JSX.Element => {
  const {
    formName,
    onlyFuture = false,
    onlyPast = false,
    minDate,
    maxDate,
    label,
    value,
    required,
    placeholder = 'MM/DD/YYYY',
    className,
    error,
    name,
    onFocus,
    onBlur,
    onChange,
    ...rest
  } = props
  const isFirstOnClose = useRef(true)
  const [placeholderFlag, setPlaceholderFlag] = useState(false)
  const showAsPlaceholder = useRef(true)
  const isoTime = 'T00:00:00.00Z'
  const [dateStart, setDateStart] = useState(
    DateTime.fromObject({ day: 1, month: 1, year: 1900 }),
  )
  const [dateEnd, setDateEnd] = useState(
    DateTime.fromObject({ day: 31, month: 12, year: 2100 }),
  )
  const handleChange = useCallback(
    (date: DateTime) => {
      setDate(date)
      onChange?.(`${date.toSQLDate()}${isoTime}` as any)
      showAsPlaceholder.current = false
    },
    [onChange],
  )
  const [date, setDate] = useState(
    value ? DateTime.fromSQL(value.split('T')[0]) : DateTime.now(),
  )

  const dropdownRef = useRef<DropdownRef>(null)

  const close = () => {
    dropdownRef.current?.close()
  }
  useEffect(() => {
    if (placeholder) {
      setPlaceholderFlag(true)
    }
    if (value) {
      setPlaceholderFlag(false)
      showAsPlaceholder.current = false
      handleChange(DateTime.fromSQL(value.split('T')[0]))
    }
  }, [handleChange, placeholder, value])
  useEffect(() => {
    if (onlyFuture) {
      setDateStart(DateTime.now())
      setDateEnd(DateTime.fromObject({ day: 31, month: 12, year: 2100 }))
    }
    if (onlyPast) {
      setDateStart(DateTime.fromObject({ day: 1, month: 1, year: 1900 }))
      setDateEnd(DateTime.now())
    }
    if (minDate) {
      setDateStart(minDate)
    }
    if (maxDate) {
      setDateEnd(maxDate)
    }
  }, [onlyFuture, onlyPast, minDate, maxDate])
  const labelClassNames = classNames('label-container__label', {
    'label-container__label--error': error,
  })
  const dispatch = useDispatch()
  return (
    <div className={classNames('seh-container', 'datepicker', className)}>
      {label && (
        <div className="label-container">
          <label className={labelClassNames}>
            {label}
            {required && (
              <span className="label-container__label__star-required">*</span>
            )}
          </label>
        </div>
      )}
      <input {...rest} type="hidden" required={required} name={name} />
      <Dropdown
        onOpen={() => {
          setPlaceholderFlag(false)
          dispatch(focus(formName || 'SEForm', name as string))
        }}
        onClose={() => {
          isFirstOnClose.current
            ? (isFirstOnClose.current = false)
            : dispatch(touch(formName || 'SEForm', name as string))
        }}
        ref={dropdownRef}
        trigger={
          <DatepickerInput
            className={classNames({ 'seh-container--error__input': error })}
            date={date}
            onChangeHandle={handleChange}
            placeholder={placeholder}
            placeholderFlag={!!placeholder && !value && placeholderFlag}
            showAsPlaceholder={showAsPlaceholder.current}
            error={error}
          />
        }
      >
        <DatepickerMenu
          date={date}
          onChange={handleChange}
          minDate={dateStart}
          maxDate={dateEnd}
          close={close}
        />
      </Dropdown>
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  )
}
export default Datepicker
