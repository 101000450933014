import { DateTime } from 'luxon'
import { DatePartProps } from '../components/DatePart/DatePart'

const MONTH = 'month',
  DAY = 'day',
  YEAR = 'year'

const DATE_PART_END = 2,
  DATE_PART_START = 0,
  MONTH_END = 12,
  MONTH_START = 1,
  MONTH_STARTING_DAY = 1

export const onSelectionStart = (event: Event) => {
  event.preventDefault()
}
export const changeFocus = (
  focusedIndex: number,
  setFocusedIndex: (value: number) => void,
) => {
  if (focusedIndex === null) {
    setFocusedIndex(DATE_PART_START)
  } else {
    if (focusedIndex < DATE_PART_END) {
      setFocusedIndex(focusedIndex + 1)
    } else {
      setFocusedIndex(DATE_PART_START)
    }
  }
}
export const changeFocusBackwards = (
  focusedIndex: number,
  setFocusedIndex: (value: number) => void,
) => {
  if (focusedIndex === null) {
    setFocusedIndex(DATE_PART_END)
  } else {
    if (focusedIndex > DATE_PART_START) {
      setFocusedIndex(focusedIndex - 1)
    } else {
      setFocusedIndex(DATE_PART_END)
    }
  }
}
//DatePart increment and decrement by 1
export const incrementDatePart = (
  dateParts: DatePartProps[],
  currentDate: DateTime,
  focusedIndex: number,
) => {
  if (focusedIndex === null) return
  if (!dateParts[focusedIndex!].value) {
    return
  }
  if (
    isValueValid(
      dateParts[focusedIndex!].type,
      dateParts[focusedIndex!].value! + 1,
      currentDate,
    )
  ) {
    dateParts[focusedIndex!] = {
      ...dateParts[focusedIndex!],
      value: dateParts[focusedIndex!].value! + 1,
    }
  }
}
export const decrementDatePart = (
  dateParts: DatePartProps[],
  currentDate: DateTime,
  focusedIndex: number,
) => {
  if (focusedIndex === null) return
  if (!dateParts[focusedIndex!].value || dateParts[focusedIndex].value === 1) {
    return
  }
  if (
    isValueValid(
      dateParts[focusedIndex!].type,
      dateParts[focusedIndex!].value! - 1,
      currentDate,
    )
  ) {
    dateParts[focusedIndex!] = {
      ...dateParts[focusedIndex!],
      value: dateParts[focusedIndex!].value! - 1,
    }
  }
}
//checking is value of DatepickerInput valid
export const isValueValid = (
  type: string,
  value: number,
  currentDate: DateTime,
) => {
  if (!value) {
    return
  }
  switch (type) {
    case MONTH:
      if (value <= MONTH_END) {
        if (value < MONTH_START) {
          return false
        }
        return true
      } else {
        return false
      }
    case DAY:
      if (value >= MONTH_STARTING_DAY) {
        if (value > currentDate.daysInMonth) {
          return false
        }
        return true
      } else {
        return false
      }
    case YEAR:
      if (value <= 9999) {
        return true
      } else {
        return false
      }
    default:
      break
  }
}
//returning closest valid value for DatepickerInput if given value is invalid, returning given value if it's valid
export const validValue = (
  type: string,
  value: number,
  currentDate: DateTime,
) => {
  if (!value && value !== 0) {
    return
  }
  switch (type) {
    case MONTH:
      if (value <= MONTH_END) {
        if (value < MONTH_START) {
          return MONTH_START
        }
        return value
      } else {
        return MONTH_END
      }

    case DAY:
      if (value <= currentDate.daysInMonth) {
        if (value < MONTH_STARTING_DAY) {
          return MONTH_STARTING_DAY
        }
        return value
      } else {
        return currentDate.daysInMonth
      }

    case YEAR:
      if (value <= 9999) {
        return value
      } else {
        return value % 10000
      }

    default:
      break
  }
}
