import { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

declare global {
  interface Window {
    activeModals: string[]
  }
}
window.activeModals = []
export type PopupProps = {
  defaultOpen: boolean
  accessible?: boolean
  onOpen?: () => void | boolean
  onClose?: () => void | boolean
}
export default function usePopup({
  defaultOpen = false,
  accessible = false,
  onOpen,
  onClose,
}: PopupProps): [boolean, (val: boolean) => void] {
  const id = useRef(uuid())
  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    if (accessible) {
      window.activeModals[open ? 'push' : 'pop'](id.current)
    }
  }, [open, accessible])
  // const setOpen = useCallback(
  //   (value: boolean) => {
  //     if (value) {
  //       if (onOpen?.() === false) return
  //     } else if (onClose?.() === false) return
  //     setOpenState(value)
  //   },
  //   [onOpen, onClose],
  // )

  useEffect(() => {
    if (!accessible) return
    if (open) {
      const onEscape = (ev: KeyboardEvent) => {
        if (
          ev.key === 'Escape' &&
          window.activeModals.slice(-1)[0] === id.current
        ) {
          setOpen(false)
        }
      }

      window.addEventListener('keyup', onEscape)
      return () => {
        window.removeEventListener('keyup', onEscape)
      }
    }
  }, [open, setOpen, accessible])
  return [open, setOpen]
}
