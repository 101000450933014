import { Field, WrappedFieldArrayProps } from "redux-form";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxInput from "../../ReduxInput";
import "../index.scss";
import Translations from "../../../../translations/translations.json";
import ReduxDatepicker from "../../ReduxDatepicker";
import { globalValidators } from "../../../../utils";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import statesOptions from "../../../../utils/statesOptions";
import ReduxSelect from "../../ReduxSelect";
import ReduxToggleButton from "../../ReduxToggleButton";
import useFormValue from "../../../../hooks/useFormValue";
import { useMemo } from "react";
import { general } from "../../../../validators";

type AccidentRecordProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const AccidentRecord: React.FC<AccidentRecordProps> = ({
  fields,
  maxEntries = 3,
}) => {
  useFieldsInit(fields);

  const hasAccidentRecord = useFormValue("hasAccidentRecord");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  return (
    <div className="seh-field-array">
      <h2>{Translations["accident-record"]}</h2>
      <div className="seh-form-container__content__group">
        <Field
          name="hasAccidentRecord"
          label={Translations["has-accident-record"]}
          className="seh-form-container__content__group__field"
          component={ReduxToggleButton}
        />
      </div>
      {hasAccidentRecord &&
        fields.map((accident: any, index: number) => (
          <>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations["accident"]}`}</label>
              {index > 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => fields.remove(index)}
                >
                  {Translations["remove"]}
                </ActionButton>
              )}
            </div>
            <div key={index} className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${accident}.date`}
                    label={Translations["date"]}
                    component={ReduxDatepicker}
                    className="seh-field-array__item__info__main__field"
                    onlyPast
                    required
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                    ]}
                  />
                  <Field
                    name={`${accident}.natureOfAccident`}
                    type="text"
                    label={Translations["nature-of-accident"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${accident}.city`}
                    type="text"
                    label={Translations["city"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                  <Field
                    name={`${accident}.state`}
                    label={Translations["state"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxSelect}
                    options={statesOptions}
                    required
                    validate={validations.undefined}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${accident}.hasFatalities`}
                    label={Translations["fatalities"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${accident}.hasInjuries`}
                    label={Translations["injuries"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${accident}.hasHazardousMaterial`}
                    label={Translations["hazardous-material"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      {hasAccidentRecord && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => fields.push({})}
        >
          {Translations["add-accident"]}
        </ActionButton>
      )}
    </div>
  );
};

export default AccidentRecord;
