import { DateTime, DateObject } from 'luxon'

const MONTH_END = 12,
  WEEK_END = 7,
  MONTH_START = 1,
  WEEK_START = 1,
  MONTH_STARTING_DAY = 1

export const WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const YEARS_ON_PAGE = 25

export type ObjectDate = {
  day: number
  month: number
  year: number
}

export const Pickers = {
  DAY_PICKER: 'day-picker',
  YEAR_PICKER: 'year-picker',
  MONTH_PICKER: 'month-picker',
} as const

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

//getting array of values from one to another number
export const getValues = (from: number, to: number) => {
  const array: number[] = []
  for (let i = from; i <= to; i++) {
    array.push(i)
  }
  return array
}

//converting day type into DateTime object
export const convertToDate = (date: ObjectDate) => {
  const returnDate = DateTime.fromObject({
    day: date.day,
    month: date.month,
    year: date.year,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  return returnDate
}
export const addPreviousMonth = (weekDay: number, current: DateObject) => {
  const date =
    current.month !== MONTH_START
      ? DateTime.fromObject({
          day: MONTH_STARTING_DAY,
          month: current.month! - 1,
          year: current.year,
        })
      : DateTime.fromObject({
          day: MONTH_STARTING_DAY,
          month: MONTH_END,
          year: current.year! - 1,
        })
  const array: ObjectDate[] = []
  let monthLength = date.daysInMonth
  for (let i = WEEK_START; i < weekDay; i++) {
    array.unshift({
      day: monthLength--,
      month: date.month,
      year: date.year,
    })
  }
  return array
}
export const addNextMonth = (weekDay: number, current: DateObject) => {
  const date =
    current.month !== MONTH_END
      ? DateTime.fromObject({
          day: MONTH_STARTING_DAY,
          month: current.month! + 1,
          year: current.year,
        })
      : DateTime.fromObject({
          day: MONTH_STARTING_DAY,
          month: MONTH_START,
          year: current.year! + 1,
        }).startOf('year')
  const array: ObjectDate[] = []
  for (let i = WEEK_START; i <= WEEK_END - weekDay; i++) {
    array.push({ day: i, month: date.month, year: date.year })
  }
  return array
}
//making array of days that should be displayed in day-picker
export const prepareMonth = (date: DateTime): ObjectDate[] => {
  let array: ObjectDate[] = []

  const monthLength = date.daysInMonth
  const firstDayOfTheMonth = date.startOf('month')
  const lastDayOfTheMonth = date.endOf('month')
  const firstWeekDay = firstDayOfTheMonth.weekday
  const lastWeekDay = lastDayOfTheMonth.weekday
  firstWeekDay !== WEEK_START &&
    array.push(...addPreviousMonth(firstWeekDay, date.toObject()))
  for (let i = MONTH_START; i <= monthLength; i++) {
    array.push({
      day: i,
      month: date.month,
      year: date.year,
    })
  }

  lastWeekDay !== WEEK_END &&
    array.push(...addNextMonth(lastWeekDay, date.toObject()))

  return array
}
export const getDateInfo = (
  date: DateTime,
): { monthName: string; year: number } => {
  return { monthName: date.monthLong, year: date.year }
}
