import { useEffect, useMemo } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";

import { general, numberFieldValidation } from "../../../validators";
import Translations from "../../../translations/translations.json";
import useFieldsInit from "../../../hooks/useFieldsInit";
import ReduxInput from "../../../components/redux-ui/ReduxInput";

const emeregenctyContactsTranslationsKeys: Record<
  number,
  keyof typeof Translations
> = {
  0: "primary-emergency-contact",
  1: "secondary-emergency-contact",
};

type EmergencyContactsProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const EmergencyContacts: React.FC<EmergencyContactsProps> = ({
  fields,
  maxEntries = 2,
}) => {
  useFieldsInit(fields);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  useEffect(() => {
    if (fields.length === maxEntries) return;
    for (let i = 0; i < maxEntries - 1; i++) fields.push({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="seh-field-array">
      <h2>{Translations["emergency-contact"]}</h2>
      {fields.map((contact: string, index: number) => (
        <>
          <label className="seh-field-array__subtitle">
            {Translations[emeregenctyContactsTranslationsKeys[index]]}
          </label>
          <div className="seh-form-container__content__group">
            <Field
              name={`${contact}.name`}
              type="text"
              label={Translations["name"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
            <Field
              name={`${contact}.phoneNumber`}
              type="number"
              label={Translations["phone-number"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              placeholder="e.g. 9876543210"
              validate={[
                validations.undefined,
                numberFieldValidation.exactDigits(
                  Translations["error-phone-number"],
                  10
                ),
                general.emergencyContactsUniquePhoneNumbers(
                  Translations["emergency-contact-duplicated-phone-numbers"]
                ),
              ]}
            />
            <Field
              name={`${contact}.relationship`}
              type="text"
              label={Translations["relationship"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
        </>
      ))}
    </div>
  );
};

export default EmergencyContacts;
