import { PatchService } from "../../services/patchService";
import httpClient from "../httpClient";

const addDeclarationOfEmployment =  (token: string) => 
    httpClient.post(`form/drivers/${token}/declaration-of-employment`);

const removeDeclarationOfEmployment =  (data: Record<string, string>) => {
    const token = PatchService.token;
    return  httpClient.delete(`form/drivers/${token}/declaration-of-employment`, {
      data: data,
    });
  };

  const updateDeclarationOfEmployment = (
    id: string,
    data: Record<string, any>
  ) => httpClient.patch(`/declaration-of-employment/${id}`, data);


// eslint-disable-next-line import/no-anonymous-default-export
export default { 
    addDeclarationOfEmployment,   
    removeDeclarationOfEmployment,
    updateDeclarationOfEmployment,
};
  