import { useEffect } from "react";
import { FieldArrayFieldsProps } from "redux-form";

export default function useFieldsInit(fields: FieldArrayFieldsProps<any>) {
  useEffect(() => {
    if (fields.length === 0) {
      fields.push({});
    }
  }, [fields]);
}
