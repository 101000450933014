const checkNegativeValue = (errorMsg: string) => (value: number) => {
  if (value < 0) {
    return errorMsg;
  }
};

const exactDigits = (errorMsg: string, exactNum: number) => (value: number) => {
  if (value?.toString().length > 0 && value?.toString().length !== exactNum) {
    return errorMsg;
  }
};

const maxValue = (errorMsg: string, maxValue: number) => (value: number) => {
  if (value > maxValue) {
    return errorMsg;
  }
};

const validation = {
  negativeValue: checkNegativeValue,
  exactDigits,
  maxValue,
};

export default validation;
