import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { Field } from "redux-form";
import Translations from "../../../translations/translations.json";
import useFormSyncErrors from "../../../hooks/useFormSyncErrors";
import ReduxCheckboxFieldGroupRelated from "../ReduxCheckBoxFieldGroupRelated";

import "./CheckBoxGroup.styles.scss";

type Option = {
  name: string;
  label: string;
  value: string;
};

type CheckBoxGroupProps = {
  name: string;
  label: string;
  className?: string;
  required?: boolean;
  options: Option[];
  indexInFieldArray?: number;
};
const checkGroupValue =
  (errorMsg: string, name: string, options: Option[], index?: number) =>
  (value: string, allValues: Record<string, any>) => {
    // allValues?.[name].length > index - condition when user remove field
    // from field array
    if (
      index !== undefined &&
      allValues?.[name] &&
      allValues?.[name].length > index &&
      !options.some((option) =>
        Boolean(
          Object.entries(allValues[name][index]).find(
            ([key]) => option.name === key
          )?.[1]
        )
      )
    ) {
      return errorMsg;
    }

    if (allValues?.[name] && !Object.values(allValues[name]).some(Boolean)) {
      return errorMsg;
    }
  };

const CheckBoxGroup: React.FC<CheckBoxGroupProps> = (props) => {
  const { label, name, className, required, options, indexInFieldArray } =
    props;

  const checkboxGroupClassNames = classNames("checkbox", className);
  const syncErrors: Record<string, any> = useFormSyncErrors();

  const [isAnyCheckboxTouched, setIsAnyCheckboxTouched] = useState(false);

  const parentName = useMemo(() => name.split("[")[0], [name]);

  const error = useMemo(
    () =>
      indexInFieldArray !== undefined && syncErrors[parentName]
        ? syncErrors[parentName][indexInFieldArray]
        : syncErrors[name],
    [indexInFieldArray, name, parentName, syncErrors]
  );

  const checkGroupValueValidation = useMemo(
    () =>
      checkGroupValue(
        Translations["error-required"],
        parentName,
        options,
        indexInFieldArray
      ),
    [indexInFieldArray, options, parentName]
  );

  const isErrorWhenGroupIsInFieldArray = useMemo(
    () =>
      typeof error === "object" &&
      Object.keys(error).some((key) =>
        options.some((option) => option.name === key)
      ),
    [error, options]
  );

  const shouldShowError = useMemo(
    () =>
      indexInFieldArray !== undefined ? isErrorWhenGroupIsInFieldArray : error,
    [error, indexInFieldArray, isErrorWhenGroupIsInFieldArray]
  );

  return (
    <div className={checkboxGroupClassNames}>
      <div className="checkbox__info">
        {label && (
          <label>
            {label}
            {required ? <span className="seh-required">*</span> : null}
          </label>
        )}
      </div>
      <div className="checkbox__options">
        {options.map((option) => (
          <Field
            name={`${name}.${option.name}`}
            component={ReduxCheckboxFieldGroupRelated}
            key={option.name}
            label={option.label}
            validate={checkGroupValueValidation}
            isAnyCheckboxTouched={isAnyCheckboxTouched}
            setIsAnyCheckboxTouched={setIsAnyCheckboxTouched}
          />
        ))}
      </div>
      {isAnyCheckboxTouched && shouldShowError && (
        <small className="checkbox__error">
          {Translations["error-required"]}
        </small>
      )}
    </div>
  );
};

export default CheckBoxGroup;
