import React, { useContext } from 'react'
import '../../../styles/Header.scss'
import classNames from 'classnames'
import MenuContext from '../../../context'
import { Pickers, YEARS_ON_PAGE } from '../../../utils'
import { DownArrow, LeftArrow, RightArrow } from '../../../svgs'

type HeaderProps = {
  className?: string
}
const { MONTH_PICKER } = Pickers

const Header: React.FC<HeaderProps> = ({ className }) => {
  const headerClassNames = classNames('header', className)

  const { setYearFrom, yearFrom, setShowInMenu } = useContext(MenuContext)

  return (
    <header className={headerClassNames}>
      <LeftArrow
        className="header__arrow"
        onClick={() => setYearFrom?.((oldVal) => oldVal - YEARS_ON_PAGE)}
      />
      <div
        className="header__heading"
        onClick={() => setShowInMenu?.(MONTH_PICKER)}
      >
        <p>{yearFrom}</p>
        <p>-</p>
        <p>{yearFrom && yearFrom + YEARS_ON_PAGE - 1}</p>{' '}
        <DownArrow className="header__heading__rotated" />
      </div>
      <RightArrow
        className="header__arrow"
        onClick={() => setYearFrom?.((oldVal) => oldVal + YEARS_ON_PAGE)}
      />
    </header>
  )
}
export default Header
