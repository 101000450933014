import Translations from "../../translations/translations.json";

export const workHour = [
  { value: "fullTime", text: Translations["full-time"] },
  { value: "partTime", text: Translations["part-time"] },
];

export const workPlace = [
  { label: Translations["local"], value: "local", name: "local" },
  { label: Translations["regional"], value: "regional", name: "regional" },
  {
    label: Translations["over-the-road"],
    value: "overTheRoad",
    name: "overTheRoad",
  },
];

export const typeOfEquipmentOperated = [
  { label: Translations["dry-van"], value: "dryVan", name: "dryVan" },
  { label: Translations["flatbed"], value: "flatbed", name: "flatbed" },
  { label: Translations["reefer"], value: "reefer", name: "reefer" },
  { label: Translations["other"], value: "other", name: "other" },
];

export const reasonForLeaving = [
  { label: Translations["volountary"], value: "voluntary", name: "voluntary" },
  { label: Translations["lay-off"], value: "layOff", name: "layOff" },
  {
    label: Translations["terminated"],
    value: "terminated",
    name: "terminated",
  },
  {
    label: Translations["retired"],
    value: "retired",
    name: "retired",
  },
];

export const isEligibleForRehireOptions = [
  { value: "Yes", text: Translations["yes"] },
  { value: "No", text: Translations["no"] },
  { value: "Upon Review", text: Translations["upon-review"] },
];
