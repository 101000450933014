import zipcodes from "zipcodes";
export const onlyLettersNumberAndSpacesRegex = /^[a-z\d_.,()-\s]*$/i;

const checkUndefined = (errorMsg: string) => (value: string) => {
  if (value === undefined || value === null || value === "") {
    return errorMsg;
  }
};

const checkEmptyArray = (errorMsg: string) => (value: any[]) => {
  if (!value.length) {
    return errorMsg;
  }
};

const checkFalse = (errorMsg: string) => (value: boolean) => {
  return !value ? errorMsg : null;
};

const ssnValidation = (errorMsg: string) => (value: string) => {
  const regex = /^\d{9}$/;
  const regexWithDashes = /^\d{3}-\d{2}-\d{4}$/;
  const found = !!value.match(regex) || !!value.match(regexWithDashes);
  if (found) {
    return;
  }
  return errorMsg;
};

const zipValidation = (errorMsg: string) => (value: string) => {
  const result = zipcodes.lookup(value);
  if (result) {
    return;
  }
  return errorMsg;
};

const emailValidation = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }

  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>[\].,;:\s@"]+\.)+[^<>()[\].,;:\s!@#$%^&*()_+=|"'{}/?]{2,})$/i;
  const found = !!value.match(emailRegex);

  if (found) {
    return;
  } else {
    return errorMsg;
  }
};

const onlyLettersNumberAndSpaces = (errorMsg: string) => (value: string) => {
  if (!onlyLettersNumberAndSpacesRegex.test(value)) return errorMsg;
  return;
};
const emergencyContactsUniquePhoneNumbers =
  (errorMsg: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    const emergencyContacts = allValues?.["emergencyContacts"] as {
      name: string;
      phoneNumber: string;
      relationship: string;
    }[];
    if (!value || !emergencyContacts) {
      return;
    }

    if (
      emergencyContacts.filter(({ phoneNumber }) => phoneNumber === value)
        .length > 1
    )
      return errorMsg;
  };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  undefined: checkUndefined,
  ssnValidation,
  zipValidation,
  checkFalse,
  checkEmptyArray,
  emailValidation,
  onlyLettersNumberAndSpaces,
  emergencyContactsUniquePhoneNumbers,
};
