import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import useFormValue from "../../../../hooks/useFormValue";
import { general } from "../../../../validators";
import Translations from "../../../../translations/translations.json";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import ReduxToggleButton from "../../ReduxToggleButton";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxDatepicker from "../../ReduxDatepicker";
import ReduxTextarea from "../../ReduxTextarea";
import { globalValidators } from "../../../../utils";
import api from "../../../../api";
import useQueryParams from "../../../../hooks/useQueryParams";
import { PatchService } from "../../../../services/patchService";
import { useDispatch } from "react-redux";
import { patch } from "../../../../store/actions";

type EmploymentGap = {
  hasBeenConvictedOfCriminalAct: boolean;
  hasNotBeenEmployed: boolean;
  from: string;
  to: string;
  activity: string;
};

type DeclarationOfEmploymentProps = {
  maxEntries?: number;
  callBack?: any;
} & WrappedFieldArrayProps<EmploymentGap>;

const DeclarationOfEmployment: React.FC<DeclarationOfEmploymentProps> = ({
  fields,
  maxEntries = 9,
  callBack,
}) => {
  const [employmentsGaps, setEmploymentGaps] = useState<string[]>([]);

  useFieldsInit(fields);

  const {
    params: { token },
  } = useQueryParams<{ token: string }>();

  const dispatch = useDispatch();

  const declarationOfEmploymentIds = useFormValue("declarationOfEmploymentIds");
  const declarationOfEmployment = useFormValue("declarationOfEmployment");
  const hasDeclarationOfEmployment = useFormValue("hasDeclarationOfEmployment");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
    }),
    []
  );

  const addDeclarationOfEmployment = useCallback(async () => {
    const {
      data: { driver },
    } = await api.declarationOfEmployment.addDeclarationOfEmployment(token);

    callBack("declarationOfEmploymentIds", driver.declarationOfEmploymentIds);
    dispatch(patch(driver));
    PatchService.patch();
  }, [callBack, dispatch, token]);

  const removeDeclarationOfEmployment = useCallback(
    async (index: number) => {
      try {
        const {
          data: { driver },
        } = await api.declarationOfEmployment.removeDeclarationOfEmployment({
          declarationOfEmploymentId: employmentsGaps[index],
        });
        callBack(
          "declarationOfEmploymentIds",
          driver.declarationOfEmploymentIds
        );
        dispatch(patch(driver));
        PatchService.patch();
        fields.remove(index);
      } catch (err) {
        console.error(err);
      }
    },
    [callBack, dispatch, employmentsGaps, fields]
  );

  const updateDeclarationOfEmployment = async (
    value: string | boolean,
    attribute: string,
    index: number
  ) => {
    try {
      await api.declarationOfEmployment.updateDeclarationOfEmployment(
        employmentsGaps[index],
        {
          [`${attribute}`]: value,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setEmploymentGaps(declarationOfEmploymentIds);
  }, [declarationOfEmploymentIds]);

  useEffect(() => {
    if (fields?.length === 1 && declarationOfEmploymentIds?.length === 0) {
      addDeclarationOfEmployment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declarationOfEmploymentIds?.length, fields?.length]);

  return (
    <div className="seh-field-array">
      <h2>{Translations["declaration-of-employment"]}</h2>
      <div className="seh-form-container__content__group">
        <Field
          name="hasDeclarationOfEmployment"
          label={Translations["has-declaration-of-employment"]}
          className="seh-form-container__content__group__field"
          component={ReduxToggleButton}
        />
      </div>
      {hasDeclarationOfEmployment &&
        fields.map((gap, index: number) => (
          <React.Fragment key={declarationOfEmploymentIds[index]}>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations["employment-gap"]}`}</label>
              {index > 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => removeDeclarationOfEmployment(index)}
                >
                  {Translations["remove"]}
                </ActionButton>
              )}
            </div>
            <div className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${gap}.from`}
                    label={Translations["date-from"]}
                    className="seh-field-array__item__info__main__field"
                    required
                    component={ReduxDatepicker}
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                      globalValidators.beforeFieldValidation(
                        Translations["error-before-field-validation"],
                        `${gap}`
                      ),
                      globalValidators.checkEmploymenGaptDateValidation(
                        Translations["error-employment-gap"],
                        `${gap}`
                      ),
                    ]}
                    onChange={(e: any) => {
                      updateDeclarationOfEmployment(e, "from", index);
                    }}
                  />
                  <Field
                    name={`${gap}.to`}
                    label={Translations["date-to"]}
                    className="seh-field-array__item__info__main__field"
                    required
                    component={ReduxDatepicker}
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                      globalValidators.afterFieldValidation(
                        Translations["error-after-field-validation"],
                        `${gap}`
                      ),
                      globalValidators.checkEmploymentGapOneMonthValidation(
                        Translations["error-one-month"],
                        `${gap}`
                      ),
                      globalValidators.checkEmploymenGaptDateValidation(
                        Translations["error-employment-gap"],
                        `${gap}`
                      ),
                    ]}
                    onChange={(e: any) => {
                      updateDeclarationOfEmployment(e, "to", index);
                    }}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${gap}.activity`}
                    label={Translations["activity"]}
                    required
                    className="seh-field-array__item__info__main__field"
                    component={ReduxTextarea}
                    validate={validations.undefined}
                    onChange={(e: { target: { value: string } }) => {
                      updateDeclarationOfEmployment(
                        e.target.value,
                        "activity",
                        index
                      );
                    }}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${gap}.hasBeenConvictedOfCriminalAct`}
                    label={Translations["convicted-of-criminal-act"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                    onChange={() => {
                      updateDeclarationOfEmployment(
                        !declarationOfEmployment[index]
                          ?.hasBeenConvictedOfCriminalAct,
                        "hasBeenConvictedOfCriminalAct",
                        index
                      );
                    }}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${gap}.hasNotBeenEmployed`}
                    label={Translations["not-employed"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                    onChange={() => {
                      updateDeclarationOfEmployment(
                        !declarationOfEmployment[index]?.hasNotBeenEmployed,
                        "hasNotBeenEmployed",
                        index
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      {hasDeclarationOfEmployment && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => {
            fields.push({} as EmploymentGap);
            addDeclarationOfEmployment();
          }}
        >
          {Translations["add-employment-gap"]}
        </ActionButton>
      )}
    </div>
  );
};

export default DeclarationOfEmployment;
