import SEForm from "../components/SEForm";
import EmploymentHistoryFormSubmition from "../pages/EmploymentHistoryFormSubmition/EmploymentHistoryFormSubmition.page";
import EmploymentHistoryPage from "../pages/EmploymentHistoryPage/EmploymentHistoryPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import RatingPage from "../pages/RatingPage/RatingPage";
import SubmitionPage from "../pages/SubmitionPage/SubmitionPage";
import { EnhancedRouteProps } from "./EnhancedRoute/Enhanced.route";

export default [
  { path: "/", exact: true, component: SEForm },
  { path: "/reviews", component: RatingPage },
  { path: "/submitted", component: SubmitionPage },
  {
    path: "/employment-history-submitted",
    component: EmploymentHistoryFormSubmition,
  },
  {
    path: "/employment-history",
    component: EmploymentHistoryPage,
  },
  { path: "*", component: ErrorPage },
] as Array<EnhancedRouteProps>;
