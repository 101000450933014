import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ReduxInput from '../../components/redux-ui/ReduxInput';
import Button from '../../components/ui/Button/Button';
import ReduxToggleButton from '../../components/redux-ui/ReduxToggleButton';
import useFormValue from '../../hooks/useFormValue';
import Translations from '../../translations/translations.json';
import statesOptions from '../../utils/statesOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../components/ui';
import { formatDate } from '../../utils/formatDate';
import classNames from 'classnames';
import { scrollToFirstValidationError } from '../../utils/scrollToFirstValidationError';

const Step5 = (props: any) => {
  const { handleSubmit, onPreviousPage } = props;

  const otherTest = useFormValue('otherTest');
  const hasLessThan2YearsOfExperience = useFormValue(
    'hasLessThan2YearsOfExperience'
  );
  const firstName = useFormValue('firstName');
  const middleName = useFormValue('middleName');
  const lastName = useFormValue('lastName');
  const primaryDriverLicence = useFormValue('primaryDriverLicence');
  const currentAddress = useFormValue('currentAddress');

  const renderDate = formatDate(primaryDriverLicence.expirationDate);
  const renderLicenceState = statesOptions.find(
    (el) => el.value === primaryDriverLicence.state
  );
  const renderCAState = statesOptions.find(
    (el) => el.value === currentAddress.state
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <div className="seh-form-container__content">
          <h2>{Translations['drivers-licence-requirements']}</h2>
          <div className="seh-info-container">
            <p className="seh-info">{Translations['step-5-info-1']}</p>
            <p className="seh-info">{Translations['step-5-info-2']}</p>
            <p className="seh-info">{Translations['step-5-info-3']}</p>
            <p className="seh-additional-info">
              {Translations['step-5-info-4']}
            </p>
            <p className="seh-additional-info">
              {Translations['step-5-info-5']}
            </p>
          </div>
          <div className="seh-form-container__content__group">
            <Input
              label={Translations['drivers-name']}
              className="seh-form-container__content__group__field"
              readOnly
              value={`${firstName} ${middleName} ${lastName}`}
            />
            <Field
              name="primaryDriverLicence.licenceNumber"
              label={Translations['drivers-licence-number']}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              readOnly
            />
          </div>
          <div className="seh-form-container__content__group">
            <Input
              label={Translations['expiration-date']}
              className="seh-form-container__content__group__field"
              readOnly
              value={renderDate}
            />
            <Field
              name="primaryDriverLicence.type"
              label={Translations['type']}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              readOnly
            />
            <Input
              label={Translations['state']}
              className="seh-form-container__content__group__field"
              readOnly
              value={renderLicenceState?.label}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="driversComment"
              label={Translations['comment']}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
            />
          </div>
        </div>
        <div className={classNames('seh-form-container__content','seh-form-container__content--2-years-of-experience')}> 
          <h2>{Translations['driver-road-test-examination']}</h2>
          <div className="seh-form-container__content__group">
            <Field
              name="hasLessThan2YearsOfExperience"
              label={Translations['less-than-2-years-ex']}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          {hasLessThan2YearsOfExperience && false && ( // remove false if they want this on form
            <>
              <div className="seh-form-container__content__group">
                <Input
                  label={Translations['drivers-name']}
                  className="seh-form-container__content__group__field"
                  readOnly
                  value={`${firstName} ${middleName} ${lastName}`}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="currentAddress.zip"
                  label={Translations.zip}
                  component={ReduxInput}
                  className="seh-form-container__content__group__field"
                  readOnly
                />
                <Field
                  name="currentAddress.city"
                  label={Translations.city}
                  component={ReduxInput}
                  className="seh-form-container__content__group__field"
                  readOnly
                />
              </div>
              <div className="seh-form-container__content__group">
                <Input
                  name="currentAddress.state"
                  label={Translations.state}
                  className="seh-form-container__content__group__field"
                  value={renderCAState?.label}
                  readOnly
                />
                <Field
                  name="currentAddress.address"
                  label={Translations['drivers-address']}
                  component={ReduxInput}
                  className="seh-form-container__content__group__field"
                  readOnly
                />
              </div>
              <div className="seh-info-container">
                <p className="seh-info">{Translations['step-5-info-6']}</p>
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="preTripInspection"
                  label={Translations['pre-trip-inspection']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="combinationUnits"
                  label={Translations['combination-units']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="placingEquipment"
                  label={Translations['placing-equipment']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="emergencyEquipment"
                  label={Translations['emergency-equipment']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="operatingVehicleInTraffic"
                  label={Translations['operating-vehicle-in-traffic']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="turningVehicle"
                  label={Translations['turning-vehicle']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="breakingAndSlowing"
                  label={Translations['breaking-and-slowing']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="breakingAndParking"
                  label={Translations['breaking-and-parking']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="otherTest"
                  label={Translations['other']}
                  className="seh-form-container__content__group__field"
                  component={ReduxToggleButton}
                />
              </div>
            </>
          )}
          {hasLessThan2YearsOfExperience && otherTest && false && ( // remove false if they want this on form
            <div className="seh-form-container__content__group">
              <Field
                name="otherTestExplain"
                label={Translations['other-explain']}
                className="seh-form-container__content__group__field"
                component={ReduxInput}
              />
            </div>
          )}
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations['previous-page']}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations['next-page']}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'SEForm',
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step5);
