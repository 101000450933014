import React, { useContext } from 'react'
import '../../../styles/Header.scss'
import classNames from 'classnames'
import MenuContext from '../../../context'
import { DownArrow, LeftArrow, RightArrow } from '../../../svgs'
import { getDecrementedMonth, getIncrementedMonth } from '../../../../utils'
import { Pickers } from '../../../utils'

type HeaderProps = {
  className?: string
}
const { YEAR_PICKER } = Pickers

const Header: React.FC<HeaderProps> = ({ className }) => {
  const headerClassNames = classNames('header', className)
  const { setCurrentDate, setShowInMenu, currentDate } = useContext(MenuContext)

  return (
    <header className={headerClassNames}>
      <LeftArrow
        className="header__arrow"
        onClick={() =>
          setCurrentDate?.((oldVal) => getDecrementedMonth(oldVal))
        }
      />
      <div
        className="header__heading"
        onClick={() => setShowInMenu?.(YEAR_PICKER)}
      >
        <p>{currentDate?.monthLong}</p>
        <p>{currentDate?.year}</p>
        <DownArrow />
      </div>
      <RightArrow
        className="header__arrow"
        onClick={() =>
          setCurrentDate?.((oldVal) => getIncrementedMonth(oldVal))
        }
      />
    </header>
  )
}
export default Header
