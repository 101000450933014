import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import './MultiSelect.scss';
import { DefaultInputProps } from '../Input/Input';
import { transformValues } from './utils';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';
import { PatchService } from '../../../services/patchService';
import { selectOptionType } from '../Select/Select.component';

export type MultiSelectProps = {
  error?: string;
  label?: string;
  options: selectOptionType[];
} & DefaultInputProps;

const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
  const {
    error,
    disabled,
    className,
    label,
    required,
    placeholder,
    value,
    options,
    onChange,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const handleOnChange = (values: any) => {
    const result = values.map((value: any) => value.value);
    onChange?.(result);
    const name = [`${rest.name}`];
    dispatch(touch('SEForm', ...name));
  };

  return (
    <div
      className={classNames(
        'seh-container',
        { 'seh-container--error': error },
        { 'seh-container--disabled': disabled },
        className
      )}
      onBlur={() => PatchService.patch()}
    >
      {label && (
        <div>
          <label>
            {label}
            {required ? <span className="seh-required">*</span> : null}
          </label>
        </div>
      )}
      <Select
        className="seh-multi-select-container"
        classNamePrefix={error ? 'seh-multi-select-error' : 'seh-multi-select'}
        isMulti
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleOnChange}
        defaultValue={transformValues(value as string[], options)}
      />
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  );
};

export default MultiSelect;
