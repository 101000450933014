import React, { useMemo } from "react";
import { RouteComponentProps, RouteProps } from "react-router-dom";

export type EnhancedRouteProps = {
  componentProps?: Record<string, any>;
} & RouteProps<string> &
  RouteComponentProps;

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const { component, componentProps, ...rest } = props;
  const Component = props.component as React.FC;
  const route = useMemo(() => {
    return <Component {...rest} {...componentProps} />;
  }, [Component, componentProps, rest]);
  return route;
};
export default EnhancedRoute;
