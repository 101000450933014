import { useEffect, useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import ReduxInput from "../../components/redux-ui/ReduxInput";
import ReduxToggleButton from "../../components/redux-ui/ReduxToggleButton";
import PreviousAddresses from "../../components/redux-ui/FieldArrayComponents/PreviousAddresses/PreviousAddresses";
import Button from "../../components/ui/Button/Button";
import useFormValue from "../../hooks/useFormValue";
import Fields from "../../components/redux-ui/FieldArrayComponents/Fields/Fields.component";
import general from "../../validators/general";
import numberValidation from "../../validators/numberFieldValidation";
import Translations from "../../translations/translations.json";
import ReduxDatepicker from "../../components/redux-ui/ReduxDatepicker";
import "../../index.scss";
import { globalValidators } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import usePreviousValue from "../../hooks/usePreviousValue";
import constants from "../../constants";
import zipcodes from "zipcodes";
import { toast } from "react-toastify";
import ReduxTextarea from "../../components/redux-ui/ReduxTextarea";
import { scrollToFirstValidationError } from "../../utils/scrollToFirstValidationError";

const Step2 = (props: any) => {
  const { handleSubmit, onPreviousPage, change } = props;

  const hasWorkedForThisCompanyBefore = useFormValue(
    "hasWorkedForThisCompanyBefore"
  );
  const isEmployed = useFormValue("isEmployed");
  const hasBeenBonded = useFormValue("hasBeenBonded");
  const hasBeenConvictedOfFelony = useFormValue("hasBeenConvictedOfFelony");
  const isUnableToPerformJobFunctions = useFormValue(
    "isUnableToPerformJobFunctions"
  );

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      negativeValue: numberValidation.negativeValue(
        Translations["error-negative-value"]
      ),
      checkFalse: general.checkFalse(Translations["error-check-false"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  const previousAddress = useFormValue("previousAddress");
  const previousValueOfPA = usePreviousValue(previousAddress);

  useEffect(() => {
    if (previousValueOfPA) {
      previousAddress.forEach((element: any, index: number) => {
        if (previousValueOfPA[index]?.zip !== element.zip) {
          if (element?.zip?.length === constants.ZIP_LENGTH) {
            const result = zipcodes.lookup(element.zip);
            change(`previousAddress[${index}].city`, result?.city);
            change(`previousAddress[${index}].state`, result?.state);
          } else {
            change(`previousAddress[${index}].city`, "");
            change(`previousAddress[${index}].state`, "");
          }
        }
      });
    }
  }, [previousAddress, previousValueOfPA, change]);

  const hasUsaLegalRight = useFormValue("hasUsaLegalRight");
  const hasProofOfAge = useFormValue("hasProofOfAge");

  useEffect(() => {
    if (!hasProofOfAge) {
      toast.error(Translations["toast-error-proof-of-age"]);
    }
  }, [hasProofOfAge]);

  useEffect(() => {
    if (!hasUsaLegalRight) {
      toast.error(Translations["toast-error-have-legal-right"]);
    }
  }, [hasUsaLegalRight]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <Fields name="previousAddress" component={PreviousAddresses} />
        <div className="seh-form-container__content">
          <h2>{Translations["eligibily-to-work"]}</h2>
          <div className="seh-form-container__content__group">
            <Field
              name="hasUsaLegalRight"
              label={Translations["has-usa-legal-right"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
              validate={validations.checkFalse}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="hasWorkedForThisCompanyBefore"
              label={Translations["has-worked-for-this-company-before"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
            {hasWorkedForThisCompanyBefore && (
              <Field
                name="previousPosition.where"
                type="text"
                label={Translations["where"]}
                className="seh-form-container__content__group__field"
                component={ReduxInput}
                required
                validate={[
                  validations.undefined,
                  validations.checkSpecialCharacters,
                ]}
              />
            )}
          </div>
          {hasWorkedForThisCompanyBefore && (
            <>
              <div className="seh-form-container__content__group">
                <Field
                  name="previousPosition.from"
                  label={Translations["date-from"]}
                  component={ReduxDatepicker}
                  className="seh-form-container__content__group__field"
                  required
                  onlyPast
                  validate={[
                    validations.undefined,
                    globalValidators.onlyPast,
                    globalValidators.beforeFieldValidation(
                      Translations["error-before-field-validation"],
                      "previousPosition"
                    ),
                  ]}
                />
                <Field
                  name="previousPosition.to"
                  label={Translations["date-to"]}
                  component={ReduxDatepicker}
                  className="seh-form-container__content__group__field"
                  required
                  onlyPast
                  validate={[
                    validations.undefined,
                    globalValidators.onlyPast,
                    globalValidators.afterFieldValidation(
                      Translations["error-after-field-validation"],
                      "previousPosition"
                    ),
                  ]}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="previousPosition.position"
                  type="text"
                  label={Translations["position"]}
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                />
              </div>
              <div className="seh-form-container__content__group">
                <Field
                  name="previousPosition.reasonForLeaving"
                  type="text"
                  label={Translations["reason-for-leaving"]}
                  className="seh-form-container__content__group__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                />
              </div>
            </>
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="hasProofOfAge"
              label={Translations["has-proof-of-age"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
              validate={validations.checkFalse}
            />
            <Field
              name="birthDate"
              label={Translations["date-of-birth"]}
              component={ReduxDatepicker}
              className="seh-form-container__content__group__field"
              required
              onlyPast
              validate={[
                validations.undefined,
                globalValidators.onlyPast,
                globalValidators.checkBirthDateValidation,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="isEmployed"
              label={Translations["is-employed"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
            {!isEmployed && (
              <Field
                name="howLongSinceLastEmployment"
                type="text"
                label={Translations["how-long-since-last-employment"]}
                className="seh-form-container__content__group__field"
                component={ReduxInput}
                required
                validate={[
                  validations.undefined,
                  validations.checkSpecialCharacters,
                ]}
              />
            )}
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="whoReferredYou"
              type="text"
              label={Translations["who-referred-you"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="hasBeenBonded"
              label={Translations["has-been-bonded"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
            {hasBeenBonded && (
              <Field
                name="bondingCompanyName"
                type="text"
                label={Translations["bonding-company-name"]}
                className="seh-form-container__content__group__field"
                component={ReduxInput}
                required
                validate={[
                  validations.undefined,
                  validations.checkSpecialCharacters,
                ]}
              />
            )}
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="hasBeenConvictedOfFelony"
              label={Translations["has-been-convicted-of-felony"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          {hasBeenConvictedOfFelony && (
            <div className="seh-form-container__content__group">
              <Field
                name="explainConviction"
                label={Translations["please-explain"]}
                className="seh-form-container__content__group__field"
                component={ReduxTextarea}
                maxLength={80}
                required
                validate={validations.undefined}
              />
            </div>
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="isUnableToPerformJobFunctions"
              label={Translations["is-unable-to-perform-job-functions"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          {isUnableToPerformJobFunctions && (
            <div className="seh-form-container__content__group">
              <Field
                name="explainIfYouWish"
                type="text"
                label={Translations["please-explain"]}
                className="seh-form-container__content__group__field"
                component={ReduxTextarea}
                maxLength={80}
                required
                validate={validations.undefined}
              />
            </div>
          )}
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations["previous-page"]}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations["next-page"]}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "SEForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step2);
