import { useEffect, useMemo, useState } from "react";
import { change, Field, WrappedFieldArrayProps } from "redux-form";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxInput from "../../ReduxInput";
import ReduxToggleButton from "../../ReduxToggleButton";
import "../index.scss";
import general from "../../../../validators/general";
import numberValidation from "../../../../validators/numberFieldValidation";
import Translations from "../../../../translations/translations.json";
import ReduxDatepicker from "../../ReduxDatepicker";
import { globalValidators } from "../../../../utils";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import Button from "../../../ui/Button/Button";
import { Input } from "../../../ui";
import useQueryParams from "../../../../hooks/useQueryParams";
import api from "../../../../api";
import { useDispatch } from "react-redux";
import { patch } from "../../../../store/actions";
import useFormValue from "../../../../hooks/useFormValue";
import { PatchService } from "../../../../services/patchService";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import constants from "../../../../constants";
import zipcodes from "zipcodes";

type EmploymentHistoryProps = {
  maxEntries?: number;
  callBack?: any;
} & WrappedFieldArrayProps;

const EmploymentHistory: React.FC<EmploymentHistoryProps> = ({
  fields,
  maxEntries = 12,
  callBack,
}) => {
  useFieldsInit(fields);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      negativeValue: numberValidation.negativeValue(
        Translations["error-negative-value"]
      ),
      zipValidation: general.zipValidation(Translations["error-zip-invalid"]),
      emailValidation: general.emailValidation(Translations["error-email"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  const { params } = useQueryParams<{ token: string }>();

  const prefillEmploymentHistory = async (index: number) => {
    setOpenDot((old) =>
      old.map((val, ind) => {
        return ind === index ? !val : val;
      })
    );
  };

  const dispatch = useDispatch();

  const employmentHistoryIds = useFormValue("employmentHistoryIds");
  const employmentHistory = useFormValue("employmentHistory");

  const [employments, setEmployments] = useState<string[]>([]);

  useEffect(() => {
    setEmployments(employmentHistoryIds);
  }, [employmentHistoryIds]);

  const create = async () => {
    const {
      data: { driver },
    } = await api.employmentHistory.addEmploymentHistory(params.token);
    callBack("employmentHistoryIds", driver.employmentHistoryIds);
    dispatch(patch(driver));
    PatchService.patch();
  };

  useEffect(() => {
    if (fields.length === 1 && employmentHistoryIds.length === 0) {
      create();
    }
  }, [employmentHistoryIds.length, fields.length, params.token]);

  const [openDot, setOpenDot] = useState<boolean[]>([true]);
  const [dotNumbers, setDotNumbers] = useState<string[]>([""]);

  useEffect(() => {
    for (let i = 0; i < employmentHistoryIds.length; i++) {
      setOpenDot([...openDot, true]);
      setDotNumbers([...dotNumbers, ""]);
    }
  }, []);

  const handlePrefillEmploymentHistory = async (index: number) => {
    try {
      const { data } = await api.employmentHistory.prefillEmploymentHistory(
        dotNumbers[index]
      );

      if (data) {
        callBack(`employmentHistory[${index}].name`, data["Legal Name"]);
        callBack(
          `employmentHistory[${index}].address.address`,
          data["Physical Address"].address
        );
        callBack(
          `employmentHistory[${index}].address.city`,
          data["Physical Address"].city
        );
        callBack(
          `employmentHistory[${index}].address.state`,
          data["Physical Address"].state
        );
        callBack(
          `employmentHistory[${index}].address.zip`,
          data["Physical Address"].zip
        );
        callBack(`employmentHistory[${index}].phone`, data["Phone"]);
        callBack(`employmentHistory[${index}].email`, data["Email"]);
      }

      if (data) {
        const {
          data: { employmentHistory },
        } = await api.employmentHistory.updateEmploymentHistory(
          employments[index],
          {
            name: data["Legal Name"],
            address: {
              address: data["Physical Address"].address,
              city: data["Physical Address"].city,
              state: data["Physical Address"].state,
              zip: data["Physical Address"].zip,
            },
            phone: data["Phone"],
            email: data["Email"],
          }
        );
      }
    } catch (error) {
      toast.error("Invalid DOT number.");
    }
  };

  const handleRemoveEmploymentHistory = async (index: number) => {
    const {
      data: { driver },
    } = await api.employmentHistory.removeEmploymentHistory({
      employmentHistoryId: employments[index],
    });
    callBack("employmentHistoryIds", driver.employmentHistoryIds);
    dispatch(patch(driver));
    PatchService.patch();

    const tmpOpenDot = openDot;
    tmpOpenDot.splice(index, 1);
    setOpenDot(tmpOpenDot);
    const tmpDotNumbers = dotNumbers;
    tmpDotNumbers.splice(index, 1);
    setDotNumbers(tmpDotNumbers);
    fields.remove(index);
  };

  const updateEmploymentHistorySpecAttr = async (
    value: any,
    attribute: string,
    index: number
  ) => {
    const {
      data: { employmentHistory },
    } = await api.employmentHistory.updateEmploymentHistory(
      employments[index],
      {
        [`${attribute}`]: value,
      }
    );
  };

  const updateZip = (value: number, index: number) => {
    const result = zipcodes.lookup(value);

    const employment = `employmentHistory[${index}]`;
    if (result) {
      dispatch(change("SEForm", `${employment}.address.city`, result?.city));

      dispatch(change("SEForm", `${employment}.address.state`, result?.state));

      updateEmploymentHistorySpecAttr(value, "address.zip", index);
      updateEmploymentHistorySpecAttr(result?.city, "address.city", index);
      updateEmploymentHistorySpecAttr(result?.state, "address.state", index);
    } else {
      dispatch(change("SEForm", `${employment}.address.city`, ""));

      dispatch(change("SEForm", `${employment}.address.state`, ""));
    }
  };

  return (
    <div className="seh-field-array">
      <h2>{Translations["employment-history"]}</h2>
      <div className="seh-info-container">
        <p className="seh-info">{Translations["step-3-info-1"]}</p>
        <p className="seh-info">{Translations["step-3-info-2"]}</p>
      </div>
      {fields.map((employment: any, index: number) => (
        <>
          <div className="seh-field-array__title">
            <label className="seh-field-array__title__label">{`#${index + 1} ${
              Translations["employment"]
            }`}</label>
            {index > 0 ? (
              <ActionButton
                className="seh-field-array__title__button"
                action="remove"
                onClick={() => {
                  handleRemoveEmploymentHistory(index);
                }}
              >
                {Translations["remove"]}
              </ActionButton>
            ) : null}
          </div>
          {openDot[index] && (
            <div className="seh-field-array__dot-number">
              <Input
                label="DOT Number"
                type="number"
                onChange={(event: any) => {
                  setDotNumbers((old) =>
                    old.map((value, ind) => {
                      if (ind === index) {
                        return event.target.value;
                      } else return value;
                    })
                  );
                }}
              />
              <Button
                className="seh-field-array__dot-number__prefill-btn"
                onClick={() => {
                  handlePrefillEmploymentHistory(index);
                }}
                type="button"
              >
                Prefill
              </Button>
            </div>
          )}

          <div key={index} className="seh-field-array__item">
            <div className="seh-field-array__item__info">
              <div className="seh-field-array__title">
                <p style={{ marginTop: 8 }} className="seh-info">
                  {Translations["dot-number-info"]}
                </p>
              </div>
              <div className="seh-field-array__title">
                <label className="seh-field-array__title__label">
                  {Translations["employer"]}
                </label>
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.name`}
                  type="text"
                  label={Translations["name"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "name",
                      index
                    );
                  }}
                />
                <Field
                  name={`${employment}.email`}
                  type="email"
                  label={Translations["email"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "email",
                      index
                    );
                  }}
                />
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.address.zip`}
                  type="number"
                  label={Translations["zip"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  onInput={(event: any) => {
                    if (event.target.value.length > constants.ZIP_LENGTH) {
                      event.target.value = event.target.value.slice(
                        0,
                        constants.ZIP_LENGTH
                      );
                    }
                  }}
                  onChange={(event: any) =>
                    updateZip(event.target.value, index)
                  }
                  validate={[
                    validations.undefined,
                    numberValidation.exactDigits(Translations["error-zip"], 5),
                    validations.zipValidation,
                  ]}
                />

                <Field
                  name={`${employment}.address.city`}
                  type="text"
                  label={Translations["city"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={validations.undefined}
                  readOnly
                />

                <Field
                  name={`${employment}.address.state`}
                  label={Translations["state"]}
                  component={ReduxInput}
                  required
                  validate={validations.undefined}
                  readOnly
                  className="seh-field-array__item__info__main__field"
                />

                <Field
                  name={`${employment}.address.address`}
                  type="text"
                  label={Translations["address"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "address.address",
                      index
                    );
                  }}
                />
              </div>

              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.contactPerson`}
                  type="text"
                  label={Translations["contact-person"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "contactPerson",
                      index
                    );
                  }}
                />
                <Field
                  name={`${employment}.phone`}
                  type="number"
                  label={Translations["phone-number"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  placeholder="e.g. 9876543210"
                  validate={[
                    validations.undefined,
                    numberValidation.exactDigits(
                      Translations["error-phone-number"],
                      10
                    ),
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "phone",
                      index
                    );
                  }}
                />
              </div>
              <div className="seh-field-array__title">
                <label className="seh-field-array__title__label">
                  {Translations["date"]}
                </label>
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.from`}
                  label={Translations["date-from"]}
                  component={ReduxDatepicker}
                  className="seh-field-array__item__info__main__field"
                  required
                  onlyPast
                  validate={[
                    validations.undefined,
                    globalValidators.onlyPast,
                    globalValidators.beforeFieldValidation(
                      Translations["error-before-field-validation"],
                      `${employment}`
                    ),
                    globalValidators.checkEmploymentDateValidation(
                      Translations["date-interval-employment-history"],
                      Translations["error-eh-gap-overlapping"],
                      `${employment}`
                    ),
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(ev, "from", index);
                  }}
                />
                <Field
                  name={`${employment}.to`}
                  label={Translations["date-to"]}
                  component={ReduxDatepicker}
                  className="seh-field-array__item__info__main__field"
                  required
                  onlyPast
                  validate={[
                    validations.undefined,
                    globalValidators.onlyPast,
                    globalValidators.afterFieldValidation(
                      Translations["error-after-field-validation"],
                      `${employment}`
                    ),
                    globalValidators.checkEmploymentDateValidation(
                      Translations["date-interval-employment-history"],
                      Translations["error-eh-gap-overlapping"],
                      `${employment}`
                    ),
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(ev, "to", index);
                  }}
                />
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.positionHeld`}
                  type="text"
                  label={Translations["position-held"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "positionHeld",
                      index
                    );
                  }}
                />
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.reasonForLeaving`}
                  type="text"
                  label={Translations["reason-for-leaving"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxInput}
                  required
                  validate={[
                    validations.undefined,
                    validations.checkSpecialCharacters,
                  ]}
                  onChange={(ev: any) => {
                    updateEmploymentHistorySpecAttr(
                      ev.target.value,
                      "reasonForLeaving",
                      index
                    );
                  }}
                />
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.subjectOfFmcrs`}
                  label={Translations["subject-of-fmcrs"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxToggleButton}
                  onChange={() => {
                    updateEmploymentHistorySpecAttr(
                      //HOTFIX: investigate this why value isnt corect on change
                      !employmentHistory[index]?.subjectOfFmcrs,
                      "subjectOfFmcrs",
                      index
                    );
                  }}
                />
              </div>
              <div className="seh-field-array__item__info__main">
                <Field
                  name={`${employment}.drugAndAlchTest`}
                  label={Translations["drug-and-alch-test"]}
                  className="seh-field-array__item__info__main__field"
                  component={ReduxToggleButton}
                  onChange={() => {
                    updateEmploymentHistorySpecAttr(
                      //HOTFIX: investigate this why value isnt corect on change
                      !employmentHistory[index]?.drugAndAlchTest,
                      "drugAndAlchTest",
                      index
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ))}
      <ActionButton
        className="seh-add-button"
        action="add"
        disabled={fields.length === maxEntries}
        onClick={() => {
          fields.push({});
          setOpenDot([...openDot, true]);
          setDotNumbers([...dotNumbers, ""]);
          create();
        }}
      >
        {Translations["add-employment"]}
      </ActionButton>
      <div className="seh-info-container">
        <p className="seh-info">{Translations["step-3-info-3"]}</p>
        <p className="seh-info">{Translations["step-3-info-4"]}</p>
      </div>
    </div>
  );
};

export default EmploymentHistory;
