import { Field, reduxForm } from "redux-form";
import Button from "../../components/ui/Button/Button";
import ReduxToggleButton from "../../components/redux-ui/ReduxToggleButton";
import Translations from "../../translations/translations.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { scrollToFirstValidationError } from "../../utils/scrollToFirstValidationError";

const Step8 = (props: any) => {
  const { handleSubmit, onPreviousPage } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <div className="seh-form-container__content">
          <h2>{Translations["receipt"]}</h2>
          <div className="seh-info-container">
            <p className="seh-info">{Translations['receipt-page-text']}</p>
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="designatedPerson"
              label={Translations["designated-person"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="subjectToPart382"
              label={Translations["subject-to-part-382"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="sufficientSafetyInfo"
              label={Translations["sufficient-safety-info"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="prohibitedDriverConduct"
              label={Translations["prohibited-driver-conduct"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="driverTestingCircumstances"
              label={Translations["driver-testing-circumstances"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="testProcedures"
              label={Translations["test-procedures"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="administeredTests"
              label={Translations["administered-tests"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="testRefusal"
              label={Translations["test-refusal"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="subpartB"
              label={Translations["subpartB"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="alcoholConcentration"
              label={Translations["alcohol-concentration"]}
              className="seh-form-container__content__group__field"
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <div className="toggle-custom-label-container">
              <div className="toggle-custom-label-container__label">
                {Translations["controlled-substances"]}
                <ul>
                  <li> {Translations["individuals-health-symphtoms"]}</li>
                  <li> {Translations["work"]}</li>
                  <li> {Translations["available-methods-of-intervening"]}</li>
                  <li> {Translations["personal-life"]}</li>
                </ul>
              </div>
              <Field
                name="controlledSubstances"
                className="seh-form-container__content__group__field"
                component={ReduxToggleButton}
              />
            </div>
          </div>
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations['previous-page']}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations['submit']}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "SEForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step8);
