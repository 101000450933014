import classNames from "classnames";
import React from "react";
import { StarIcon } from "../../icons/Star.icon";
import { StarOutlineIcon } from "../../icons/StarOutline.icon";

import "./StarButton.styles.scss";

type StarButtonProps = {
  className?: string;
  isActive: boolean;
  onClick: () => void;
};

const StarButton: React.FC<StarButtonProps> = (props) => {
  const { isActive = false, onClick, className } = props;

  const classes = classNames("star-button__icon", className);

  return (
    <div className="star-button" onClick={() => onClick()}>
      {isActive ? (
        <StarIcon className="star-button__icon" />
      ) : (
        <StarOutlineIcon className={classes} />
      )}
    </div>
  );
};
export default StarButton;
