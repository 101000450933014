import RatingForm from "../../components/RatingForm/RatingForm";

import "./RatingPage.scss";

const RatingPage: React.FC = () => {
  return (
    <div className="app-container">
      <div className="seh-app">
        <RatingForm />
      </div>
    </div>
  );
};
export default RatingPage;
