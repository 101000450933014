import Translations from "../translations/translations.json";

import {
  general,
  numberFieldValidation,
  onlyFutureValidation,
  onlyPastValidation,
  afterFieldValidation,
  beforeFieldValidation,
  dateFromToValidation,
  checkBirthDateValidation,
  checkEmploymentDateValidation,
} from "../validators";
import dateValidation, {
  checkEmploymentGapDateValidation,
  checkEmploymentGapOneMonthValidation,
} from "../validators/dateValidation";

const globalValidators = {
  undefined: general.undefined("This field is required"),
  onlyFuture: onlyFutureValidation.date("Date in future is expected"),
  onlyPast: onlyPastValidation.date("Date from past is expected"),
  numberNotNegative: numberFieldValidation.negativeValue(
    "Negative value is not allowed"
  ),
  afterFieldValidation: afterFieldValidation.date,
  beforeFieldValidation: beforeFieldValidation.date,
  dateFromToValidation: dateFromToValidation.date,
  checkBirthDateValidation: checkBirthDateValidation.date(
    Translations["error-birth-date"]
  ),
  checkEmploymentDateValidation: checkEmploymentDateValidation.date,
  checkEmploymentGapOneMonthValidation:
    checkEmploymentGapOneMonthValidation.date,
  checkEmploymenGaptDateValidation: checkEmploymentGapDateValidation.date,
  dateAfterFieldValidation: afterFieldValidation.after,
  dateBeforeFieldValidation: beforeFieldValidation.before,
  dateIntervalValidation: dateValidation.dateInterval,
};

export default globalValidators;
