import { useMemo } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxInput from "../../ReduxInput";
import "../index.scss";
import general from "../../../../validators/general";
import Translations from "../../../../translations/translations.json";
import ReduxDatepicker from "../../ReduxDatepicker";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import ReduxSelect from "../../ReduxSelect";
import statesOptions from "../../../../utils/statesOptions";
import ReduxToggleButton from "../../ReduxToggleButton";
import useFormValue from "../../../../hooks/useFormValue";

type DriverLicencesProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const DriverLicences: React.FC<DriverLicencesProps> = ({
  fields,
  maxEntries = 3,
}) => {
  useFieldsInit(fields);

  const hasOtherDriverLicenses = useFormValue("hasOtherDriverLicenses");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  return (
    <div className="seh-field-array">
      <h2>{Translations["experience-and-qualifications"]}</h2>
      <div className="seh-field-array__title">
        <label className="seh-field-array__title__label">
          {Translations["current-licence"]}
        </label>
      </div>
      <div className="seh-form-container__content__group">
        <Field
          name="primaryDriverLicence.state"
          label={Translations["state"]}
          className="seh-form-container__content__group__field"
          component={ReduxSelect}
          options={statesOptions}
          required
          validate={validations.undefined}
        />
        <Field
          name="primaryDriverLicence.licenceNumber"
          label={Translations["licence-number"]}
          type="text"
          className="seh-form-container__content__group__field"
          component={ReduxInput}
          required
          validate={[validations.undefined, validations.checkSpecialCharacters]}
        />
      </div>
      <div className="seh-form-container__content__group">
        <Field
          name="primaryDriverLicence.type"
          type="text"
          label={Translations["type"]}
          className="seh-form-container__content__group__field"
          component={ReduxInput}
          required
          validate={[validations.undefined, validations.checkSpecialCharacters]}
        />
        <Field
          name="primaryDriverLicence.expirationDate"
          label={Translations["expiration-date"]}
          component={ReduxDatepicker}
          className="seh-form-container__content__group__field"
          required
          validate={validations.undefined}
        />
      </div>
      <div className="seh-form-container__content__group">
        <Field
          name="hasOtherDriverLicenses"
          label={Translations["has-other-licence"]}
          className="seh-form-container__content__group__field"
          component={ReduxToggleButton}
        />
      </div>
      {hasOtherDriverLicenses &&
        fields.map((licence: any, index: number) => (
          <>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations["licence"]}`}</label>
              {index !== 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => fields.remove(index)}
                >
                  {Translations["remove"]}
                </ActionButton>
              )}
            </div>
            <div key={index} className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${licence}.state`}
                    label={Translations["state"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxSelect}
                    options={statesOptions}
                    required
                    validate={validations.undefined}
                  />
                  <Field
                    name={`${licence}.licenceNumber`}
                    type="text"
                    label={Translations["licence-number"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${licence}.type`}
                    type="text"
                    label={Translations["type"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                  <Field
                    name={`${licence}.expirationDate`}
                    label={Translations["expiration-date"]}
                    component={ReduxDatepicker}
                    className="seh-field-array__item__info__main__field"
                    required
                    validate={validations.undefined}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      {hasOtherDriverLicenses && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => fields.push({})}
        >
          {Translations["add-licence"]}
        </ActionButton>
      )}
    </div>
  );
};

export default DriverLicences;
