import { useMemo } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxInput from "../../ReduxInput";
import "../index.scss";
import general from "../../../../validators/general";
import Translations from "../../../../translations/translations.json";
import ReduxDatepicker from "../../ReduxDatepicker";
import { globalValidators } from "../../../../utils";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import ReduxToggleButton from "../../ReduxToggleButton";
import useFormValue from "../../../../hooks/useFormValue";

type TrafficConvictionsProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const TrafficConvictions: React.FC<TrafficConvictionsProps> = ({
  fields,
  maxEntries = 3,
}) => {
  useFieldsInit(fields);

  const hasTrafficConvictions = useFormValue("hasTrafficConvictions");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  return (
    <div className="seh-field-array">
      <h2>{Translations["traffic-convictions"]}</h2>
      <div className="seh-form-container__content__group">
        <Field
          name="hasTrafficConvictions"
          label={Translations["has-traffic-convictions"]}
          className="seh-form-container__content__group__field"
          component={ReduxToggleButton}
        />
      </div>
      {hasTrafficConvictions &&
        fields.map((tc: any, index: number) => (
          <>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations["conviction"]}`}</label>
              {index > 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => fields.remove(index)}
                >
                  {Translations["remove"]}
                </ActionButton>
              )}
            </div>
            <div key={index} className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${tc}.location`}
                    type="text"
                    label={Translations["location"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                  <Field
                    name={`${tc}.date`}
                    label={Translations["date"]}
                    component={ReduxDatepicker}
                    className="seh-field-array__item__info__main__field"
                    onlyPast
                    required
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                    ]}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${tc}.charge`}
                    type="text"
                    label={Translations["charge"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${tc}.hasPenalty`}
                    label={Translations["penalty"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxToggleButton}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      {hasTrafficConvictions && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => fields.push({})}
        >
          {Translations["add-conviction"]}
        </ActionButton>
      )}
    </div>
  );
};

export default TrafficConvictions;
