import React, { useContext } from "react";
import "./DayPicker.scss";
import "../../DatepickerMenu.styles.scss";

import classNames from "classnames";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";

import { isDateValid } from "../../../utils";
import { convertToDate, ObjectDate, prepareMonth, WEEK } from "../../utils";
import MenuContext from "../../context";

type DayPickerProps = {
  className?: string;
};
const DayPicker: React.FC<DayPickerProps> = ({ className }) => {
  const {
    date = DateTime.now(),
    minDate = DateTime.now().set({ year: 1900 }),
    maxDate = DateTime.now().set({ year: 2100 }),
    currentDate = DateTime.now(),
    onChange,
    close,
  } = useContext(MenuContext);

  const array = prepareMonth(currentDate!);

  const selectedFieldClassNames = classNames(
    "datepicker-menu-container__main__field",
    "datepicker-menu-container__main__field--selected",
    "datepicker-menu-container__main__field--valid"
  );
  const differentMonthFieldClassNames = classNames(
    "datepicker-menu-container__main__field--different-month"
  );
  const validFieldClassNames = classNames(
    "datepicker-menu-container__main__field",
    "datepicker-menu-container__main__field--valid"
  );
  const invalidFieldClassNames = classNames(
    "datepicker-menu-container__main__field",
    "datepicker-menu-container__main__field--invalid"
  );
  const dayPickerClassNames = classNames(
    "datepicker-menu-container__main",
    "day-picker",
    className
  );

  const handleDayChange = (date: ObjectDate) => {
    onChange?.(
      DateTime.fromObject({
        day: date.day,
        month: date.month,
        year: date.year,
      })
    );
    close?.();
  };

  return (
    <div className={dayPickerClassNames}>
      {WEEK.map((day) => (
        <div
          className="datepicker-menu-container__main__day-picker-field"
          key={uuid()}
        >
          {day}
        </div>
      ))}
      {array.map((dateObject) => {
        return (
          <div
            className={
              +convertToDate(dateObject) === +date.startOf("day")
                ? selectedFieldClassNames
                : isDateValid(convertToDate(dateObject), minDate, maxDate)
                ? classNames(
                    {
                      [differentMonthFieldClassNames]:
                        dateObject.month !== currentDate.month,
                    },
                    validFieldClassNames
                  )
                : invalidFieldClassNames
            }
            key={uuid()}
            onClick={
              isDateValid(convertToDate(dateObject), minDate, maxDate)
                ? () => {
                    handleDayChange(dateObject);
                  }
                : () => {}
            }
          >
            {dateObject.day}
          </div>
        );
      })}
    </div>
  );
};

export default DayPicker;
