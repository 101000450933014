import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import './DatepickerMenu.styles.scss'

import { Pickers } from './utils'
import MenuContext from './context'
import pickers, { PickersType } from './picker'

export type DatepickerMenuProps = {
  date: DateTime
  onChange: (date: DateTime) => void
  minDate: DateTime
  maxDate: DateTime
  close?: () => void
}

const { DAY_PICKER } = Pickers

const DatepickerMenu: React.FC<DatepickerMenuProps> = ({
  date,
  onChange,
  minDate,
  maxDate,
  close,
}) => {
  const [currentDate, setCurrentDate] = useState(date.startOf('day'))
  const [showInMenu, setShowInMenu] = useState<PickersType>(DAY_PICKER)
  const [yearFrom, setYearFrom] = useState(currentDate.year - 12)

  useEffect(() => {
    setCurrentDate(date.startOf('day'))
  }, [date])

  const value = {
    yearFrom,
    showInMenu,
    currentDate,
    setCurrentDate,
    setShowInMenu,
    setYearFrom,
    minDate,
    maxDate,
    onChange,
    close,
  }
  const Header = pickers[showInMenu].header
  const Main = pickers[showInMenu].picker
  return (
    <div className="datepicker-menu-container">
      <MenuContext.Provider value={value}>
        <header>
          <Header />
        </header>
        <main>
          <Main />
        </main>
      </MenuContext.Provider>
    </div>
  )
}

export default DatepickerMenu
