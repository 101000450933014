import React, { useContext, useEffect } from 'react'
import './YearPicker.scss'
import '../../DatepickerMenu.styles.scss'

import { DateTime } from 'luxon'
import { getValues, Pickers, YEARS_ON_PAGE } from '../../utils'
import classNames from 'classnames'
import { isYearValid, setValidYearDay } from '../../../utils'
import MenuContext from '../../context'

const { MONTH_PICKER } = Pickers

type YearPickerProps = {
  className?: string
}
const YearPicker: React.FC<YearPickerProps> = ({ className }) => {
  const {
    date = DateTime.now(),
    minDate = DateTime.now().set({ year: 1900 }),
    maxDate = DateTime.now().set({ year: 2100 }),
    yearFrom = DateTime.now().year - Math.floor(YEARS_ON_PAGE / 2),
    setShowInMenu,
    setCurrentDate,
    setYearFrom,
    currentDate,
  } = useContext(MenuContext)

  const yearsDisplayed = getValues(yearFrom, yearFrom + YEARS_ON_PAGE - 1)

  const selectedFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--selected',
    'datepicker-menu-container__main__field--valid',
  )
  const validFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--valid',
  )
  const invalidFieldClassNames = classNames(
    'datepicker-menu-container__main__field',
    'datepicker-menu-container__main__field--invalid',
  )
  const YearPickerClassNames = classNames(
    'datepicker-menu-container__main',
    'year-picker',
    className,
  )

  useEffect(() => {
    if (currentDate)
      setYearFrom?.(currentDate.year - Math.floor(YEARS_ON_PAGE / 2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={YearPickerClassNames}>
      {yearsDisplayed.map((year) => (
        <div
          className={
            year === date.year
              ? selectedFieldClassNames
              : isYearValid(year, minDate, maxDate)
              ? validFieldClassNames
              : invalidFieldClassNames
          }
          onClick={() => {
            if (isYearValid(year, minDate, maxDate)) {
              setCurrentDate?.((oldVal) => setValidYearDay(oldVal, year))
              setShowInMenu?.(MONTH_PICKER)
            }
          }}
        >
          {year}
        </div>
      ))}
    </div>
  )
}
export default YearPicker
