import { constants } from "../../config";
import { Driver } from "../../types";
import httpClient from "../httpClient";

const getDriverById = (id: string) =>
  httpClient.get<{ driver: Driver }>(`employment-history-form/drivers/${id}`, {
    headers: {
      "x-api-key": constants.X_API_KEY,
    },
  });

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDriverById,
};
