import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import api from "../../api";
import useQueryParams from "../../hooks/useQueryParams";
import ReduxTextarea from "../redux-ui/ReduxTextarea";
import Button from "../ui/Button/Button";
import StarRatingInput from "../ui/StarRatingInput";
import { general } from "../../validators";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import Translations from "../../translations/translations.json";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { Course, Review } from "../../types";

import "./RatingForm.styles.scss";

const RatingForm = (props: InjectedFormProps<Record<string, Review>>) => {
  const { handleSubmit } = props;

  const [coursesForReview, setCoursesForReview] = useState<Course[]>([]);
  const [driverId, setDriverId] = useState<string>();
  const [loading, setLoading] = useState(true);

  const { params } = useQueryParams<{ token: string }>();

  const history = useHistory();

  const verifyReviewToken = useCallback(async () => {
    try {
      const reviewRequest = await api.reviews.verifyReviewToken(params.token);
      setCoursesForReview(reviewRequest.data.courses);
      setDriverId(reviewRequest.data.driverId);
    } catch (err) {
      toast.error((err as AxiosError).response?.data.error);
      history.push("/page-not-found");
    } finally {
      setLoading(false);
    }
  }, [history, params.token]);

  useEffect(() => {
    verifyReviewToken();
  }, [verifyReviewToken]);

  const required = useMemo(
    () => general.undefined(Translations["error-required"]),
    []
  );

  const handleReviewsSubmit = async (
    values: Record<string, Review>,
    driverId: string
  ) => {
    try {
      const reviews = Object.keys(values).reduce((acc, key) => {
        const review = values[key];
        return [
          ...acc,
          {
            driverId,
            agentId: key,
            note: review.note,
            mark: review.mark,
          },
        ];
      }, [] as Review[]);
      await api.reviews.createReview(reviews, params.token);
      history.push("/submitted");
    } catch (err) {
      toast.error((err as AxiosError).response?.data.error);
    }
  };

  return loading ? (
    <div className="seh-spinner-wrapper">
      <ClipLoader size={150} />
      <label className="seh-spinner-msg">{Translations["please-wait"]}</label>
    </div>
  ) : (
    <div className="seh-form-template rating-form">
      <h1 className="rating-header">{Translations["leave-a-review"]}</h1>
      <form
        onSubmit={handleSubmit((values) =>
          handleReviewsSubmit(values, driverId as string)
        )}
        className="rating-form"
      >
        <div className="rating-form__heading">
          <h3>{`${Translations["instructor"]}`}</h3>
          <h3>{`${Translations["department"]}`}</h3>
        </div>
        {coursesForReview.map(({ instructor, courseTypes }, index) => {
          return (
            <div className="rating-form__agent-rating-box" key={index}>
              <div className="rating-form__instructor">
                <span>{`${instructor.fullName}`}</span>
                {instructor.profileImageUrl && (
                  <img
                    className="rating-form__instructor__image"
                    src={instructor.profileImageUrl}
                    alt="instructor's infomation"
                  />
                )}
                <span>{`${courseTypes.join(", ")}`}</span>
              </div>
              <h4 className="rating-form__field-heading">
                {Translations["note"]}:
              </h4>
              <Field
                validate={required}
                name={`${instructor.id}.note`}
                component={ReduxTextarea}
                className="rating-form__note-input"
              />
              <div className="rating-form__rating-box">
                <h4 className="rating-form__field-heading">
                  {Translations["rating"]}:
                </h4>
                <Field
                  validate={required}
                  name={`${instructor.id}.mark`}
                  component={StarRatingInput}
                  className="rating-form__stars-rating-input"
                />
              </div>
            </div>
          );
        })}
        <div className="rating-form__button-box">
          <Button className="rating-form__button">
            {Translations["rate"]}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "RatingForm",
})(RatingForm);
