import { useEffect, useMemo } from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import ReduxInput from '../../ReduxInput';
import '../index.scss';
import general from '../../../../validators/general';
import numberFieldValidation from '../../../../validators/numberFieldValidation';
import Translations from '../../../../translations/translations.json';
import { DateTime } from 'luxon';
import { getUIDates } from './utils/index';

type ReduxDatepickerArrayProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const ReduxDatepickerArray: React.FC<ReduxDatepickerArrayProps> = ({
  fields,
  maxEntries = 7,
}) => {
  useEffect(() => {
    const sufix = 'T00:00:00.000Z';
    const today = DateTime.now();

    if (fields.length === 0) {
      for (let i = 0; i < maxEntries; i++) {
        const date = today.plus({ day: -1 - i });
        fields.push({ date: date.toSQLDate() + sufix });
      }
    } else {
      const date = today.plus({ day: -maxEntries });
      const foundIndex = fields
        .getAll()
        .findIndex((el) => el.date === date.toSQLDate() + sufix);

      if (foundIndex === -1) {
        fields.removeAll();
        for (let i = 0; i < maxEntries; i++) {
          const date = today.plus({ day: -1 - i });
          fields.push({ date: date.toSQLDate() + sufix });
        }
      } else if (foundIndex < maxEntries - 1) {
        for (let i = 0; i < maxEntries - foundIndex - 1; i++) {
          fields.remove(foundIndex + 1);
        }

        for (let i = maxEntries - foundIndex - 1; i > 0; i--) {
          const date = today.plus({ day: -i });
          fields.unshift({ date: date.toSQLDate() + sufix });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations['error-required']),
      negativeValue: numberFieldValidation.negativeValue(
        Translations['error-negative-value']
      ),
      maxValue: numberFieldValidation.maxValue(
        Translations['error-hours-worked'],
        14
      ),
    }),
    []
  );

  const uiDates = getUIDates(fields.getAll());

  return (
    <div className="seh-field-array">
      <h2>{Translations.dates}</h2>
      <h3>{Translations['hours-worked']}:</h3>
      <div className="on-duty-hours">
        {fields?.map((date: any, index: number) => (
          <Field
            name={`${date}.hoursWorked`}
            type="number"
            label={
              index === 0
                ? `${uiDates[index]} (${Translations['yesterday']})`
                : `${uiDates[index]}`
            }
            component={ReduxInput}
            required
            validate={[
              validations.undefined,
              validations.negativeValue,
              validations.maxValue,
            ]}
          />
        ))}
      </div>
    </div>
  );
};

export default ReduxDatepickerArray;
