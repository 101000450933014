import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { PatchService } from '../../services/patchService';
import Textarea, { TextareaProps } from '../ui/Textarea/Textarea.component';

type ReduxTextareaProps = WrappedFieldProps & TextareaProps;

const ReduxTextarea: React.FC<ReduxTextareaProps> = ({
  input,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <Textarea
      {...input}
      {...rest}
      {...(touched && { error })}
      onBlur={() => PatchService.patch()}
    />
  );
};

export default ReduxTextarea;
