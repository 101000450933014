import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  ReactElement,
  useImperativeHandle,
  forwardRef,
} from 'react'
import useResizeReposition from './hooks/useResizeReposition'
import usePositioning from './hooks/usePositioning'
import classNames from 'classnames'
import usePopup from '../../../hooks/usePopup'
import './Dropdown.scss'
import Overlay from '../Overlay'
type DropdownProps = {
  trigger: JSX.Element
  children: JSX.Element
  className?: string
  defaultOpen?: boolean
  renderAsPortal?: boolean
  background?: 'black' | 'white' | 'transparent'
  contentFullWidth?: boolean
  contentStickToTrigger?: boolean
  onChange?: () => void
  isClosableOnTrigger?: boolean
  onOpen?: () => void
  onClose?: () => void
}
export type DropdownRef = {
  open: () => void
  close: () => void
  isOpen: () => boolean
}
const Dropdown: React.ForwardRefRenderFunction<DropdownRef, DropdownProps> = (
  props,
  ref,
) => {
  const {
    trigger,
    className = '',
    defaultOpen = false,
    renderAsPortal = false,
    children,
    background = 'white',
    contentFullWidth = false,
    contentStickToTrigger,
    onChange,
    isClosableOnTrigger,
    onOpen,
    onClose,
  } = props
  const [open, setOpen] = usePopup({ defaultOpen, accessible: true })
  const [upwards, setUpwards] = useState(false)
  const [backwards, setBackwards] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
      isOpen: () => open,
    }),
    [setOpen, open],
  )
  useEffect(() => {
    onChange?.()
    if (open) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }, [open])
  usePositioning({
    triggerRef,
    contentRef,
    dropdownRef,
    setUpwards,
    setBackwards,
    open,
    renderAsPortal,
  })

  useResizeReposition({
    triggerRef,
    contentRef,
    dropdownRef,
    upwards,
    backwards,
    renderAsPortal,
  })

  const clonedTrigger = useMemo(
    () => cloneTrigger({ trigger, open, setOpen, isClosableOnTrigger }),
    [open, setOpen, trigger, isClosableOnTrigger],
  )
  const dropdownClasses = classNames([
    'dropdown',
    {
      'dropdown--open': open,
    },
    className,
  ])

  const triggerClasses = classNames('dropdown__trigger', {
    'dropdown__trigger--open': open,
  })

  const contentClasses = classNames(
    'dropdown__content',
    `dropdown__content--${background}`,
    {
      'dropdown__content--open': open,
      'dropdown__content--upwards': upwards,
      'dropdown__content--backwards': backwards,
      'dropdown__content-full-width': contentFullWidth,
      'dropdown__content-sticked-to-trigger': contentStickToTrigger,
    },
  )

  const content = useMemo(
    () => (
      <div className={contentClasses} ref={contentRef}>
        {children}
      </div>
    ),
    [contentClasses, children],
  )
  return (
    <div ref={dropdownRef} className={dropdownClasses}>
      <Overlay open={open} onClick={() => setOpen(false)} />
      <div className={triggerClasses} ref={triggerRef}>
        {clonedTrigger}
      </div>
      {content}
    </div>
  )
}

export default forwardRef(Dropdown)

const cloneTrigger = ({
  trigger,
  setOpen,
  open,
  isClosableOnTrigger,
}: {
  trigger: ReactElement
  open: boolean
  setOpen: (value: boolean) => void
  isClosableOnTrigger?: boolean
}) => {
  return React.cloneElement(trigger, {
    onClick: (ev: MouseEvent) => {
      setOpen(isClosableOnTrigger ? !open : true)
      ev.stopPropagation()
    },
  })
}
