import { DateTime } from "luxon";
import {
  transformDateToDays,
  transformDefaultValue,
} from "../components/ui/Datepicker/utils";

const checkDate =
  (errorMsg: string, minDate: DateTime, maxDate: DateTime) =>
  (value: string) => {
    if (!value) {
      return;
    }
    const date = transformDefaultValue(value);
    if (date < minDate || date > maxDate) {
      return errorMsg;
    }
  };
const onlyPast = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }
  const minDate = DateTime.fromObject({
    day: 1,
    month: 1,
    year: 1900,
  });
  const maxDate = DateTime.now();
  const date = transformDefaultValue(value);
  if (date < minDate || date > maxDate) {
    return errorMsg;
  }
};
const onlyFuture = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }
  const maxDate = DateTime.fromObject({
    day: 31,
    month: 12,
    year: 2100,
  });
  const minDate = DateTime.now();
  const date = transformDefaultValue(value);
  if (date < minDate || date > maxDate) {
    return errorMsg;
  }
};

const dateBeforeField =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, any>) => {
    if (!value || !allValues[fieldName]) return;

    const date = transformDefaultValue(value);
    const fieldDate = transformDefaultValue(allValues[fieldName]);

    if (date > fieldDate) {
      return errorMsg;
    }
  };

const dateAfterField =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, any>) => {
    if (!value || !allValues[fieldName]) return;

    const date = transformDefaultValue(value);
    const fieldDate = transformDefaultValue(allValues[fieldName]);

    if (date < fieldDate) {
      return errorMsg;
    }
  };

// field array related
const afterField =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    if (!value) {
      return;
    }
    let fieldValue;
    if (!(fieldName.indexOf("[") === -1)) {
      const splitedName = fieldName.split("[");
      fieldValue = allValues[splitedName[0]][parseInt(splitedName[1])]?.from;
    } else {
      fieldValue = allValues[fieldName]?.from;
    }
    if (!fieldValue) return;
    const date = transformDefaultValue(value);
    const fieldDate = transformDefaultValue(fieldValue);

    if (date < fieldDate) {
      return errorMsg;
    }
  };

const checkDateInterval =
  (errorMsg: string, fieldNameFrom: string, fieldNameTo: string) =>
  (value: string, allValues: Record<string, any>) => {
    if (!value || !allValues[fieldNameFrom] || !allValues[fieldNameTo]) return;
    const fieldValue = transformDefaultValue(value);
    const valueFrom = transformDefaultValue(allValues[fieldNameFrom]);
    const valueTo = transformDefaultValue(allValues[fieldNameTo]);

    if (fieldValue < valueFrom || fieldValue > valueTo) {
      return errorMsg;
    }
  };

// field array related
const beforeField =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    if (!value) {
      return;
    }
    let fieldValue;
    if (!(fieldName.indexOf("[") === -1)) {
      const splitedName = fieldName.split("[");
      fieldValue = allValues[splitedName[0]][parseInt(splitedName[1])]?.to;
    } else {
      fieldValue = allValues[fieldName]?.to;
    }
    if (!fieldValue) return;
    const date = transformDefaultValue(value);
    const fieldDate = transformDefaultValue(fieldValue);
    if (date > fieldDate) {
      return errorMsg;
    }
  };

const checkBirthDate = (errorMsg: string) => (value: string) => {
  if (!value) {
    return;
  }
  const birthDate = transformDefaultValue(value);
  const tmp = DateTime.now();
  const tmp23 = tmp.plus({ year: -23 });
  if (tmp23 < birthDate) {
    return errorMsg;
  }
};

const employmentDateValidation =
  (errorMsg: string, errorsMsgGap: string, fieldName: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    if (!value) {
      return;
    }
    let fieldValue;
    let splitedName;
    if (!(fieldName.indexOf("[") === -1)) {
      splitedName = fieldName.split("[");

      fieldValue = allValues[splitedName[0]][parseInt(splitedName[1])]?.to;
    } else {
      fieldValue = allValues[fieldName]?.to;
    }

    if (!fieldValue) return;

    const fieldDate = transformDefaultValue(fieldValue);

    const invalidDateInterval =
      allValues["employmentHistory"].filter(
        (eh: { from: string; to: string }) =>
          eh.from &&
          eh.to &&
          fieldDate >= transformDefaultValue(eh.from) &&
          fieldDate <= transformDefaultValue(eh.to)
      ).length > 1;

    let invalidEmploymentGapDateInterval;
    if (allValues["declarationOfEmployment"]) {
      invalidEmploymentGapDateInterval = allValues[
        "declarationOfEmployment"
      ]?.filter(
        (gap: { from: string; to: string }) =>
          gap.from &&
          gap.to &&
          fieldDate >= transformDefaultValue(gap.from) &&
          fieldDate <= transformDefaultValue(gap.to)
      );
    }

    if (invalidDateInterval) return errorMsg;
    if (
      invalidEmploymentGapDateInterval &&
      !!invalidEmploymentGapDateInterval?.length
    )
      return errorsMsgGap;
  };

const employmentGapOneMonth =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    if (!value) {
      return;
    }
    let fieldValue;
    if (!(fieldName.indexOf("[") === -1)) {
      const splitedName = fieldName.split("[");
      fieldValue = allValues[splitedName[0]][parseInt(splitedName[1])]?.from;
    } else {
      fieldValue = allValues[fieldName]?.from;
    }
    if (!fieldValue) return;

    const dateInDays = transformDateToDays(value);

    const fieldDateInDays = transformDateToDays(fieldValue);

    if (dateInDays && fieldDateInDays && dateInDays - fieldDateInDays < 30)
      return errorMsg;
  };

const employmentGapDateValidation =
  (errorMsg: string, fieldName: string) =>
  (value: string, allValues: Record<string, Record<string, any>>) => {
    if (!value) {
      return;
    }
    let fieldValueTo;
    let splitedName;
    if (!(fieldName.indexOf("[") === -1)) {
      splitedName = fieldName.split("[");

      fieldValueTo = allValues[splitedName[0]][parseInt(splitedName[1])]?.to;
    } else {
      fieldValueTo = allValues[fieldName]?.to;
    }

    if (!fieldValueTo) return;

    const fieldDate = transformDefaultValue(fieldValueTo);

    const invalidEmploymentHistoryDateInterval = allValues[
      "employmentHistory"
    ].filter(
      (eh: { from: string; to: string }) =>
        eh.from &&
        eh.to &&
        fieldDate >= transformDefaultValue(eh.from) &&
        fieldDate <= transformDefaultValue(eh.to)
    );
    const invalidEmploymentGapDateInterval =
      allValues["declarationOfEmployment"].filter(
        (gap: { from: string; to: string }) =>
          gap.from &&
          gap.to &&
          fieldDate >= transformDefaultValue(gap.from) &&
          fieldDate <= transformDefaultValue(gap.to)
      ).length > 1;

    if (
      invalidEmploymentHistoryDateInterval.length ||
      invalidEmploymentGapDateInterval
    )
      return errorMsg;
  };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  date: checkDate,
  dateInterval: checkDateInterval,
};
export const onlyFutureValidation = {
  date: onlyFuture,
};
export const onlyPastValidation = {
  date: onlyPast,
};
export const beforeFieldValidation = {
  date: beforeField,
  before: dateBeforeField,
};
export const afterFieldValidation = {
  date: afterField,
  after: dateAfterField,
};

export const checkBirthDateValidation = {
  date: checkBirthDate,
};

export const checkEmploymentDateValidation = {
  date: employmentDateValidation,
};
export const checkEmploymentGapOneMonthValidation = {
  date: employmentGapOneMonth,
};

export const checkEmploymentGapDateValidation = {
  date: employmentGapDateValidation,
};
