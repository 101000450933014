import patchService from "./patchService";
import employmentHistory from "./employmentHistory";
import declarationOfEmployment from "./declarationOfEmployment";
import reviews from "./agentReviews";
import driver from "./driver";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  patchService,
  employmentHistory,
  declarationOfEmployment,
  reviews,
  driver,
};
