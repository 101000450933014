import { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import ReduxInput from "../../components/redux-ui/ReduxInput";
import ReduxToggleButton from "../../components/redux-ui/ReduxToggleButton";
import Button from "../../components/ui/Button/Button";
import useFormValue from "../../hooks/useFormValue";
import general from "../../validators/general";
import EmploymentHistory from "../../components/redux-ui/FieldArrayComponents/EmploymentHistory/EmploymentHistory";
import AccidentRecord from "../../components/redux-ui/FieldArrayComponents/AccidentRecord/AccidentRecord";
import TrafficConvictions from "../../components/redux-ui/FieldArrayComponents/TrafficConvictions/TrafficConvictions";
import DriverLicences from "../../components/redux-ui/FieldArrayComponents/DriverLicences/DriverLicences";
import TruckInfo from "../../components/redux-ui/FieldArrayComponents/TruckInfo/TruckInfo";
import ReduxSelect from "../../components/redux-ui/ReduxSelect";
import Fields from "../../components/redux-ui/FieldArrayComponents/Fields/Fields.component";
import Translations from "../../translations/translations.json";
import "../../index.scss";
import { selectOptions } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ReduxMultiSelect from "../../components/redux-ui/ReduxMultiSelect";
import statesOptions from "../../utils/statesOptions";
import DeclarationOfEmployment from "../../components/redux-ui/FieldArrayComponents/DeclarationOfEmployment";
import { scrollToFirstValidationError } from "../../utils/scrollToFirstValidationError";

const formValues = {
  HAS_BEEN_DENIED_A_LICENCE: "hasBeenDeniedALicence",
  HAS_BEEN_SUSPENDED: "hasBeenSuspended",
  STRAIGHT_TRUCK: "straightTruck",
  SEMI_TRAILER: "semiTrailer",
  TWO_TRAILERS: "twoTrailers",
  THREE_TRAILERS: "threeTrailers",
  SCHOOL_BUS_8: "schoolBus8",
  SCHOOL_BUS_15: "schoolBus15",
};
const Step3 = (props: any) => {
  const { handleSubmit, onPreviousPage } = props;

  const hasBeenDeniedALicence = useFormValue(
    formValues.HAS_BEEN_DENIED_A_LICENCE
  );
  const hasBeenSuspended = useFormValue(formValues.HAS_BEEN_SUSPENDED);
  const straightTruck = useFormValue(formValues.STRAIGHT_TRUCK);
  const semiTrailer = useFormValue(formValues.SEMI_TRAILER);
  const twoTrailers = useFormValue(formValues.TWO_TRAILERS);
  const hasOperatedInAllStates = useFormValue("hasOperatedInAllStates");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      checkEmptyArray: general.checkEmptyArray(Translations["error-required"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <div className="seh-form-container__content">
          <Fields
            name="employmentHistory"
            component={EmploymentHistory}
            callBack={props.change}
          />
          <Fields
            name="declarationOfEmployment"
            component={DeclarationOfEmployment}
            callBack={props.change}
          />
          <Fields name="previousAccidents" component={AccidentRecord} />
          <Fields name="trafficConvictions" component={TrafficConvictions} />
          <Fields name="otherDriverLicences" component={DriverLicences} />
          <div
            style={{ marginTop: 16 }}
            className="seh-form-container__content__group"
          >
            <Field
              name="hasBeenDeniedALicence"
              className="seh-form-container__content__group__field"
              label={Translations["has-been-denied-a-licence"]}
              component={ReduxToggleButton}
            />
          </div>
          {hasBeenDeniedALicence && (
            <div className="seh-form-container__content__group">
              <Field
                name="licenceDeniedExplanation"
                className="seh-form-container__content__group__field"
                label={Translations["please-give-details"]}
                component={ReduxInput}
                required
                validate={[
                  validations.undefined,
                  validations.checkSpecialCharacters,
                ]}
              />
            </div>
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="hasBeenSuspended"
              className="seh-form-container__content__group__field"
              label={Translations["has-been-suspended"]}
              component={ReduxToggleButton}
            />
          </div>
          {hasBeenSuspended && (
            <div className="seh-form-container__content__group">
              <Field
                name="licenceSuspendedExplanation"
                className="seh-form-container__content__group__field"
                label={Translations["please-give-details"]}
                component={ReduxInput}
                required
                validate={[
                  validations.undefined,
                  validations.checkSpecialCharacters,
                ]}
              />
            </div>
          )}
          <h2>{Translations["driving-experience"]}</h2>
          <div className="seh-form-container__content__group">
            <Field
              name="straightTruck"
              className="seh-form-container__content__group__field"
              label={Translations["straight-truck"]}
              component={ReduxToggleButton}
            />
          </div>
          {straightTruck && (
            <Field name="straightTruckInfo" component={TruckInfo} />
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="semiTrailer"
              className="seh-form-container__content__group__field"
              label={Translations["semi-trailer"]}
              component={ReduxToggleButton}
            />
          </div>
          {semiTrailer && (
            <Field name="semiTrailerInfo" component={TruckInfo} />
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="twoTrailers"
              className="seh-form-container__content__group__field"
              label={Translations["two-trailer"]}
              component={ReduxToggleButton}
            />
          </div>
          {twoTrailers && (
            <Field name="twoTrailersInfo" component={TruckInfo} />
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="schoolBus8"
              className="seh-form-container__content__group__field"
              label={Translations["school-bus-8"]}
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="schoolBus15"
              className="seh-form-container__content__group__field"
              label={Translations["school-bus-15"]}
              component={ReduxToggleButton}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="otherDrivingExperience"
              className="seh-form-container__content__group__field"
              label={Translations["other-driving-experience"]}
              component={ReduxInput}
              validate={validations.checkSpecialCharacters}
            />
          </div>
          <h3>{Translations["states-operated-in"]}</h3>
          <div className="seh-form-container__content__group">
            <Field
              name="hasOperatedInAllStates"
              className="seh-form-container__content__group__field"
              label={Translations["all-states"]}
              component={ReduxToggleButton}
            />
          </div>
          {!hasOperatedInAllStates && (
            <div className="seh-form-container__content__group">
              <Field
                name="statesOperatedIn"
                className="seh-form-container__content__group__field"
                label={Translations["list-states"]}
                component={ReduxMultiSelect}
                options={statesOptions}
                required
                validate={validations.checkEmptyArray}
              />
            </div>
          )}
          <div className="seh-form-container__content__group">
            <Field
              name="safeDrivingAwards"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["safe-driving-award"]}
              component={ReduxInput}
              validate={validations.checkSpecialCharacters}
            />
          </div>
          <h2>{Translations["experience-and-qualifications-other"]}</h2>
          <div className="seh-form-container__content__group">
            <Field
              name="experienceThatMayHelp"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["experience-that-may-help"]}
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="otherCourses"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["other-courses"]}
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="specialEquipment"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["special-equipment"]}
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="highestGrade"
              className="seh-form-container__content__group__field"
              label={Translations["highest-grade"]}
              component={ReduxSelect}
              required
              validate={validations.undefined}
              options={selectOptions}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="education.lastSchool"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["last-school"]}
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
            <Field
              name="education.location"
              type="text"
              className="seh-form-container__content__group__field"
              label={Translations["school-location"]}
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
        </div>
        <div className="seh-button-submit">
          <Button type="button" styleType="secondary" onClick={onPreviousPage}>
            <FontAwesomeIcon icon={faArrowLeft} color="#011077" size="1x" />
            {Translations["previous-page"]}
          </Button>
          <Button type="button" onClick={handleSubmit}>
            {Translations["next-page"]}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  form: "SEForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step3);
