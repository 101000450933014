import { Field, WrappedFieldArrayProps } from 'redux-form';
import ActionButton from '../../../ui/ActionButton/ActionButton';
import ReduxInput from '../../ReduxInput';
import '../index.scss';
import Translations from '../../../../translations/translations.json';
import ReduxDatepicker from '../../ReduxDatepicker';
import { globalValidators } from '../../../../utils';
import useFieldsInit from '../../../../hooks/useFieldsInit';
import ReduxToggleButton from '../../ReduxToggleButton';
import { useMemo } from 'react';
import { general } from '../../../../validators';
import useFormValue from '../../../../hooks/useFormValue';

type AnnualReviewProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const AnnualReview: React.FC<AnnualReviewProps> = ({
  fields,
  maxEntries = 10,
}) => {
  useFieldsInit(fields);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations['error-required']),
    }),
    []
  );

  const hasViolationsInLast12Months = useFormValue(
    'hasViolationsInLast12Months'
  );

  return (
    <div className="seh-field-array">
      <h2>{Translations['annual-review']}</h2>
      <p className="seh-field-array__description">
        {Translations['annual-review-description1']}
      </p>
      <div className="seh-form-container__content__group">
        <Field
          name="hasViolationsInLast12Months"
          label={Translations['have-made-violation']}
          component={ReduxToggleButton}
          className="seh-form-container__content__group__field"
        />
      </div>
      {hasViolationsInLast12Months &&
        fields.map((review: any, index: number) => (
          <>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations['violation']}`}</label>
              {index > 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => fields.remove(index)}
                >
                  {Translations['remove']}
                </ActionButton>
              )}
            </div>
            <div key={index} className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${review}.date`}
                    label={Translations['date']}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxDatepicker}
                    onlyPast
                    required
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                    ]}
                  />
                  <Field
                    name={`${review}.offense`}
                    type="text"
                    label={Translations['offense']}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={validations.undefined}
                  />
                </div>
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${review}.location`}
                    label={Translations['location']}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={validations.undefined}
                  />
                  <Field
                    name={`${review}.vehicleType`}
                    type="text"
                    label={Translations['type-of-vehicle']}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    validate={validations.undefined}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      {hasViolationsInLast12Months && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => fields.push({})}
        >
          {Translations['add-violation']}
        </ActionButton>
      )}
      <p className="seh-field-array__description">
        {Translations['annual-review-description2']}
      </p>
    </div>
  );
};

export default AnnualReview;
