import { RefObject, useEffect } from 'react'

type Params = {
  dropdownRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
  triggerRef: RefObject<HTMLDivElement>
  upwards: boolean
  backwards: boolean
  renderAsPortal: boolean
}

const useResizeResposition = ({
  dropdownRef,
  contentRef,
  triggerRef,
  upwards,
  backwards,
  renderAsPortal,
}: Params) => {
  useEffect(() => {
    const setCoords = () => {
      const {
        top: dropdownTopOffset,
        left: dropdownLeftOffset,
      } = dropdownRef.current!.getBoundingClientRect()
      const {
        height: triggerHeight,
        width: triggerWidth,
      } = triggerRef.current!.getBoundingClientRect()
      let topOffset = dropdownTopOffset + window.scrollY
      let leftOffset = dropdownLeftOffset + window.scrollX

      if (!upwards) {
        topOffset += triggerHeight
      }
      if (backwards) {
        leftOffset += triggerWidth
      }
      contentRef!.current!.style.top = `${topOffset}px`
      contentRef!.current!.style.left = `${leftOffset}px`
    }
    if (renderAsPortal) {
      window.addEventListener('resize', setCoords)
      const cleanUp = () => {
        window.removeEventListener('resize', setCoords)
      }
      return cleanUp
    }
  }, [dropdownRef, contentRef, triggerRef, backwards, upwards, renderAsPortal])
}
export default useResizeResposition
