import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { DateObject, DateTime } from 'luxon'
import DatePart from './components/DatePart'
import '../../Input/Input.scss'
import '../../../../index.scss'
import './DatepickerInput.styles.scss'
import classNames from 'classnames'
import { DatePartProps } from './components/DatePart/DatePart'
import {
  onSelectionStart,
  changeFocus,
  changeFocusBackwards,
  validValue,
  incrementDatePart,
  decrementDatePart,
} from './utils'

export type DatepickerInputProps = {
  date: DateTime
  onChangeHandle: (date: DateTime) => void
  placeholder?: string
  placeholderFlag?: boolean
  className?: string
  error?: string
  showAsPlaceholder?: boolean
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const DAY_LENGTH = 2
const MONTH_LENGTH = 2
const YEAR_LENGH = 4

const month: DatePartProps = {
  type: 'month',
}
const day: DatePartProps = {
  type: 'day',
}
const year: DatePartProps = {
  type: 'year',
}

let dateParts: DatePartProps[] = [month, day, year]

const DatepickerInput = (props: DatepickerInputProps): JSX.Element => {
  const {
    date,
    onChangeHandle,
    onClick,
    placeholder,
    placeholderFlag,
    className,
    error,
    showAsPlaceholder,
  } = props
  const [keyInput, setKeyInput] = useState('')
  const [currentDate, setCurrentDate] = useState(date)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)
  const datepickerInput = useRef<HTMLDivElement>(null)

  dateParts = useMemo(
    () =>
      (dateParts = dateParts.map((el) => {
        return { ...el, value: date[el.type] }
      })),
    [date],
  )
  useEffect(() => {
    if (datepickerInput && datepickerInput.current) {
      datepickerInput.current.addEventListener('selectstart', onSelectionStart)
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datepickerInput.current?.removeEventListener(
          'selectstart',
          onSelectionStart,
        )
      }
    }
  }, [])

  const updateCurrentDate = useCallback(() => {
    if (!dateParts[1].value) {
      return
    }
    let obj: DateObject = {}
    if (focusedIndex === 0) {
      let tmpDate = DateTime.fromObject({
        day: 1,
        month: dateParts[0].value,
        year: currentDate.year,
      })
      if (tmpDate.daysInMonth < dateParts[1].value) {
        dateParts[1].value = tmpDate.daysInMonth
      }
    }
    dateParts.forEach((el) => {
      obj = {
        ...obj,
        [el.type]: el.value,
      }
    })

    let date = DateTime.fromObject(obj)

    setCurrentDate(date)
    onChangeHandle(date)
  }, [currentDate.year, focusedIndex, onChangeHandle])

  const inputDatePart = useCallback(
    (type: string, input: string) => {
      if (focusedIndex === null) return
      let newValue: number
      input = input.replace(/\s/g, '')
      switch (type) {
        case 'day':
          if (input.length > DAY_LENGTH) {
            input = input.slice(-DAY_LENGTH)
          }
          newValue = Number(input)
          dateParts[focusedIndex].value = validValue(
            type,
            newValue,
            currentDate,
          )
          if (input.length >= DAY_LENGTH) {
            changeFocus(focusedIndex!, setFocusedIndex)
          }
          break
        case 'month':
          if (input.length > MONTH_LENGTH) {
            input = input.slice(-MONTH_LENGTH)
          }
          newValue = Number(input)
          dateParts[focusedIndex].value = validValue(
            type,
            newValue,
            currentDate,
          )
          if (input.length >= MONTH_LENGTH) {
            changeFocus(focusedIndex!, setFocusedIndex)
          }
          break
        case 'year':
          if (input.length > YEAR_LENGH) {
            input = input.slice(-YEAR_LENGH)
          }
          newValue = Number(input)
          dateParts[focusedIndex].value = validValue(
            type,
            newValue,
            currentDate,
          )
          if (input.length >= YEAR_LENGH) {
            changeFocus(focusedIndex!, setFocusedIndex)
          }
          break

        default:
          break
      }
    },
    [currentDate, focusedIndex],
  )

  const onArrow = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      switch (event.key) {
        case 'ArrowUp':
          if (focusedIndex === null) return
          incrementDatePart(dateParts, currentDate, focusedIndex)
          updateCurrentDate()
          break
        case 'ArrowDown':
          if (focusedIndex === null) return
          decrementDatePart(dateParts, currentDate, focusedIndex)
          updateCurrentDate()
          break
        case 'ArrowLeft':
          changeFocusBackwards(focusedIndex!, setFocusedIndex)
          break
        case 'ArrowRight':
          changeFocus(focusedIndex!, setFocusedIndex)
          break
        default:
          break
      }
    },
    [currentDate, focusedIndex, updateCurrentDate],
  )

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (event.key === 'Tab') {
        changeFocus(focusedIndex!, setFocusedIndex)
      }
      onArrow(event)
      if (focusedIndex === null) return

      if (/^\d+/.test(event.key)) {
        setKeyInput(keyInput + event.key)
        let input = keyInput + event.key
        inputDatePart(dateParts[focusedIndex!].type, input)
        updateCurrentDate()
      }
    },
    [focusedIndex, inputDatePart, keyInput, onArrow, updateCurrentDate],
  )

  const onClickHandle = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      focusedIndex === null && setFocusedIndex(0)
      onClick?.(ev)
    },
    [focusedIndex, onClick],
  )
  const setFocus = (index: number) => {
    setFocusedIndex(index)
  }
  const onBlur = useCallback(
    (index: number) => {
      if (index === focusedIndex) setFocusedIndex(null)
      setKeyInput('')
    },
    [focusedIndex],
  )
  const inputContainerClassNames = classNames(
    'seh-container__input',
    'input',
    'date-input-container',
    {
      'seh-container--error__input': error,
    },
    className,
    { 'as-placeholder': showAsPlaceholder },
  )

  const input = useMemo(
    () => (
      <div
        ref={datepickerInput}
        onClick={onClickHandle}
        onKeyDown={onKeyDown}
        tabIndex={0}
        className={inputContainerClassNames}
        onPaste={(event) => event?.preventDefault()}
      >
        {placeholderFlag
          ? placeholder
          : dateParts.map((el, index) => (
              <DatePart
                {...el}
                key={index}
                hasFocus={focusedIndex === index}
                setFocus={setFocus}
                onBlur={onBlur}
                partIndex={index}
                toShow={focusedIndex === index ? keyInput : ''}
              />
            ))}
      </div>
    ),
    [
      onClickHandle,
      onKeyDown,
      inputContainerClassNames,
      placeholderFlag,
      placeholder,
      focusedIndex,
      onBlur,
      keyInput,
    ],
  )
  return input
}

export default DatepickerInput
