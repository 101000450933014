import React from 'react';
import ToggleButton, {
  ToggleButtonProps,
} from '../ui/ToggleButton/ToggleButton';
import { WrappedFieldProps } from 'redux-form';

type ReduxToggleProps = WrappedFieldProps & ToggleButtonProps;

const ReduxToggleButton: React.FC<ReduxToggleProps> = ({
  input,
  meta: { valid, error },
  meta,
  ...rest
}) => {
  return (
    <ToggleButton
      checked={input.value ? true : false}
      {...input}
      {...rest}
      {...(!valid && { error })}
    />
  );
};

export default ReduxToggleButton;
