import { useMemo } from "react";
import { Field, WrappedFieldArrayProps } from "redux-form";
import ActionButton from "../../../ui/ActionButton/ActionButton";
import ReduxInput from "../../ReduxInput";
import "../index.scss";
import general from "../../../../validators/general";
import useFieldsInit from "../../../../hooks/useFieldsInit";
import Translations from "../../../../translations/translations.json";
import ReduxToggleButton from "../../ReduxToggleButton";
import useFormValue from "../../../../hooks/useFormValue";
import { numberFieldValidation } from "../../../../validators";
import constants from "../../../../constants/index";
import ReduxDatepicker from "../../ReduxDatepicker";
import { globalValidators } from "../../../../utils";

type PreviousAddressesProps = {
  maxEntries?: number;
} & WrappedFieldArrayProps;

const PreviousAddresses: React.FC<PreviousAddressesProps> = ({
  fields,
  maxEntries = 5,
}) => {
  useFieldsInit(fields);

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      zipValidation: general.zipValidation(Translations["error-zip-invalid"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  const hadSameAddressFor3Years = useFormValue("hadSameAddressFor3Years");

  return (
    <div className="seh-field-array">
      <h2>{Translations["previous-addresses"]}</h2>
      <div className="seh-form-container__content__group">
        <Field
          name="hadSameAddressFor3Years"
          label={Translations["live-for-3-years"]}
          className="seh-form-container__content__group__field"
          component={ReduxToggleButton}
        />
      </div>
      {!hadSameAddressFor3Years &&
        fields.map((address: any, index: number) => (
          <>
            <div className="seh-field-array__title">
              <label className="seh-field-array__title__label">{`#${
                index + 1
              } ${Translations["address"]}`}</label>
              {index > 0 && (
                <ActionButton
                  className="seh-field-array__title__button"
                  action="remove"
                  onClick={() => fields.remove(index)}
                >
                  {Translations["remove"]}
                </ActionButton>
              )}
            </div>
            <div key={index} className="seh-field-array__item">
              <div className="seh-field-array__item__info">
                <div className="seh-field-array__item__info__main">
                  <Field
                    name={`${address}.zip`}
                    type="number"
                    label={Translations["zip"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    required
                    onInput={(event: any) => {
                      if (event.target.value.length > constants.ZIP_LENGTH) {
                        event.target.value = event.target.value.slice(
                          0,
                          constants.ZIP_LENGTH
                        );
                      }
                    }}
                    validate={[
                      validations.undefined,
                      numberFieldValidation.exactDigits(
                        Translations["error-zip"],
                        5
                      ),
                      validations.zipValidation,
                    ]}
                  />
                  <Field
                    name={`${address}.city`}
                    type="text"
                    label={Translations["city"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    readOnly
                    required
                    validate={validations.undefined}
                  />
                </div>
                <div className="seh-field-array__item__info__additional">
                  <Field
                    name={`${address}.state`}
                    label={Translations["state"]}
                    className="seh-field-array__item__info__main__field"
                    component={ReduxInput}
                    readOnly
                    required
                    validate={validations.undefined}
                  />
                  <Field
                    name={`${address}.address`}
                    type="text"
                    label={Translations["address"]}
                    className="seh-field-array__item__info__additional__field"
                    component={ReduxInput}
                    required
                    validate={[
                      validations.undefined,
                      validations.checkSpecialCharacters,
                    ]}
                  />
                </div>
                <div className="seh-field-array__item__info__additional">
                  <Field
                    name={`${address}.from`}
                    label={Translations["date-from"]}
                    component={ReduxDatepicker}
                    className="seh-field-array__item__info__additional__field"
                    required
                    onlyPast
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                      globalValidators.beforeFieldValidation(
                        Translations["error-before-field-validation"],
                        `${address}`
                      ),
                    ]}
                  />
                  <Field
                    name={`${address}.to`}
                    label={Translations["date-to"]}
                    component={ReduxDatepicker}
                    className="seh-field-array__item__info__additional__field"
                    required
                    onlyPast
                    validate={[
                      validations.undefined,
                      globalValidators.onlyPast,
                      globalValidators.afterFieldValidation(
                        Translations["error-after-field-validation"],
                        `${address}`
                      ),
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      {!hadSameAddressFor3Years && (
        <ActionButton
          className="seh-add-button"
          action="add"
          disabled={fields.length === maxEntries}
          onClick={() => fields.push({})}
        >
          {Translations["add-address"]}
        </ActionButton>
      )}
    </div>
  );
};

export default PreviousAddresses;
