import './Overlay.scss'
import React from 'react'

export type overlayBackground = 'transparent' | 'white' | 'black'

export type OverlayProps = {
  background?: overlayBackground
  open?: boolean
  onClick?: () => void
}

const Overlay: React.FC<OverlayProps> = (props) => {
  const { background = 'transparent', open = false, onClick } = props

  return (
    <div
      className={`overlay ${open ? `open open--${background}` : 'closed'}`}
      onClick={onClick}
    ></div>
  )
}
export default Overlay
