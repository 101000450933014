import React from 'react';
import classNames from 'classnames';
import './Button.scss';

export type DefaultButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = {
  styleType?: 'primary' | 'secondary';
  size?: 's' | 'm' | 'l' | 'xl';
} & DefaultButtonProps;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    styleType = 'primary',
    size = 'm',
    className,
    children,
    ...rest
  } = props;
  return (
    <button
      className={classNames(
        'seh-button',
        `seh-button--${styleType}`,
        `seh-button--${size}`,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
