import React, { useState, useEffect } from "react";
import { DefaultInputProps } from "../../ui/Input/Input";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import "./RadioGroup.styles.scss";
import RadioSVG from "./RadioSVG";

type Option = {
  value: string;
  text: string;
};

export type RadioGroupProps = {
  options: Array<Option>;
  label?: string;
  error?: string;
  onChangeHandle?: ({ value }: { value: string }) => void;
  value?: string;
} & DefaultInputProps;

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  label,
  error,
  onChangeHandle,
  value,
  ...rest
}) => {
  const id = uuid();
  const defaultVal = options.find((el) => el.value === value);
  const [radioValue, setRadioValue] = useState(defaultVal?.value);

  useEffect(() => {
    onChangeHandle?.({ value: radioValue || "" });
  }, [onChangeHandle, radioValue]);

  return (
    <div className={classNames("seh-radio-group", rest.className)}>
      {label && (
        <label>
          {label}
          {rest.required ? <span className="seh-required">*</span> : null}
        </label>
      )}
      <div className="seh-radio-group__container">
        {options.map((option: Option, index: number) => (
          <div className="seh-radio-group__container__option" key={index}>
            <input
              name={name}
              type="radio"
              id={`${id}${option.value}`}
              value={option.value}
              required={rest.required}
              checked={option.value === radioValue}
              onChange={() => setRadioValue(option.value)}
            />
            <RadioSVG onClick={() => setRadioValue(option.value)} />
            <label htmlFor={`${id}${option.value}`}>{option.text}</label>
          </div>
        ))}
      </div>
      {error && <small className="seh-radio-group__error">{error}</small>}
    </div>
  );
};
export default RadioGroup;
