import React from 'react'
import Select, { SelectProps } from '../ui/Select/Select.component'
import { WrappedFieldProps } from 'redux-form'

type ReduxSelectProps = WrappedFieldProps & SelectProps
const ReduxSelect: React.FC<ReduxSelectProps> = ({
  input,
  meta: { touched, error },
  onSelect,
  ...rest
}) => {
  return (
    <Select
      onSelect={({ value }: { value: string }) => {
        input.onChange(value)
      }}
      input={input}
      {...rest}
      {...(touched && { error })}
    />
  )
}

export default ReduxSelect
