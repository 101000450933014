import useReduxForm from "./useReduxForm";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";

export default function useFormValue(name: string) {
  const { form } = useReduxForm();
  return useSelector(
    (state) => (getFormValues(form)(state) as Record<string, any>)?.[name]
  );
}
