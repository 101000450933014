export const scrollToFirstValidationError = (): void => {
  setTimeout(
    () =>
      document.querySelector(`[class*="error"]`)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      }),
    0
  );
};
