import React from "react";
import classNames from "classnames";
import "./Input.scss";
import { onlyLettersNumberAndSpacesRegex } from "../../../validators/general";

export type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export type InputProps = {
  label?: string;
  error?: string;
  initialValue?: string;
  handleBlur?: (value: string) => void;
} & DefaultInputProps;

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    className,
    error,
    label,
    required,
    disabled,
    handleBlur,
    onBlur,
    ...rest
  } = props;

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
    handleBlur?.(event.target.value);
  };

  const preventSpecialCharacters = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "@" && rest.type === "email") return true;
    if (!onlyLettersNumberAndSpacesRegex.test(e.key)) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <div
      className={classNames(
        "seh-container",
        { "seh-container--error": error },
        { "seh-container--disabled": disabled },
        className
      )}
    >
      {label && (
        <div>
          <label>
            {label}
            {required ? <span className="seh-required">*</span> : null}
          </label>
        </div>
      )}
      <div
        className={classNames(
          "seh-container__input",
          { "seh-container--error__input": error },
          { "seh-container--disabled__input": disabled }
        )}
      >
        <input
          disabled={disabled}
          required={required}
          onBlur={blurHandler}
          onKeyDown={preventSpecialCharacters}
          {...rest}
        />
      </div>
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  );
};

export default Input;
