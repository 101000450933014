import httpClient from "../httpClient";

const patchService = async (email: string) => {
  return await httpClient.post("form/drivers", {
    email,
  });
};

const getDriverByToken = async (token: string) => {
  return httpClient.get(`/form/drivers/${token}`);
};

const submitForm = async (token: string, driver: any) => {
  return httpClient.post(`/form/drivers/${token}/submit`, { driver });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  patchService,
  getDriverByToken,
  submitForm,
};
