import React, {
  useRef,
  createRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import classNames from "classnames";
import useWindowSize from "../../../hooks/useWindowSize";
import "./FormProgression.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

/**
 * @name FormProgression
 *
 * Displays progress bar filled to the step passed via props
 *
 * @returns {JSX}
 */

export type Stage = {
  name: string
}

export type FormProgressionProps = {
  stages: Stage[]
  labelName: string
  currentStage: number
  className?: string
}

const FormProgression = ({
  stages,
  currentStage,
  labelName,
  className,
}: FormProgressionProps): JSX.Element => {
  const circlesRefs = useRef(stages.map(() => createRef<HTMLDivElement>()))
  const progressFillerRef = useRef<HTMLDivElement>(null)
  const [barFillerWidth, setBarFillerWidth] = useState('')

  const calculateWidth = useCallback((): void => {
    if (currentStage >= stages.length) {
      setBarFillerWidth('100%')
      return
    }
    if (currentStage < 1) {
      setBarFillerWidth('0')
      return
    }
    const result =
      (circlesRefs.current[currentStage].current?.getBoundingClientRect()
        .x as number) -
      (progressFillerRef.current?.getBoundingClientRect().x as number)
    setBarFillerWidth(`${Math.round(result - 5)}px`)
  }, [currentStage, stages.length])

  useWindowSize(calculateWidth)

  useEffect(() => {
    calculateWidth()
  })

  return (
    <div className={classNames(['progression', className])}>
      <div className="progression__bar">
        <div
          className="progression__bar__filler"
          ref={progressFillerRef}
          style={{ width: barFillerWidth }}
        ></div>
      </div>
      <div className="progression__stages">
        {stages.map((stage, index) => {
          return (
            <div
              key={index}
              className={classNames([
                'progression__stages__stage',
                {
                  'progression__stages__stage--disabled':
                    index + 1 <= currentStage,
                },
              ])}
            >
              {index + 1 <= currentStage ? (
                <div
                  className="progression__stages__stage__circle_icon"
                  ref={circlesRefs.current[index]}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color="white"/>
                </div>
              ) : (
                <div
                  className="progression__stages__stage__circle"
                  ref={circlesRefs.current[index]}
                >
                  <div className="progression__stages__stage__circle__empty" />
                </div>
              )}
              <div className="progression__stages__stage__label">
                <span className="progression__stages__stage__label__text">
                  {labelName}
                </span>
                &nbsp;
                <span className="progression__stages__stage__label__index">
                  {index + 1}
                </span>
              </div>

              <div className="progression__stages__stage__name">
                {stage.name}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

FormProgression.defaultProps = {
  currentStage: 1,
}

export default FormProgression
