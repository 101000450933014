import { useEffect, useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import Fields from "../../components/redux-ui/FieldArrayComponents/Fields/Fields.component";
import EmergencyContacts from "./components/EmergencyContacts.component";
import ReduxInput from "../../components/redux-ui/ReduxInput";
import Button from "../../components/ui/Button/Button";
import general from "../../validators/general";
import Translations from "../../translations/translations.json";
import ReduxDatepicker from "../../components/redux-ui/ReduxDatepicker";
import numberValidation from "../../validators/numberFieldValidation";
import { globalValidators } from "../../utils";
import { DateTime } from "luxon";
import zipcodes from "zipcodes";
import useFormValue from "../../hooks/useFormValue";
import constants from "../../constants/index";
import { scrollToFirstValidationError } from "../../utils/scrollToFirstValidationError";

const Step1 = (props: any) => {
  const { handleSubmit } = props;

  const currentAddress = useFormValue("currentAddress");

  const validations = useMemo(
    () => ({
      undefined: general.undefined(Translations["error-required"]),
      negativeValue: numberValidation.negativeValue(
        Translations["error-negative-value"]
      ),
      ssnValidation: general.ssnValidation(Translations["error-ssn"]),
      zipValidation: general.zipValidation(Translations["error-zip-invalid"]),
      checkSpecialCharacters: general.onlyLettersNumberAndSpaces(
        Translations["error-special-characters"]
      ),
    }),
    []
  );

  useEffect(() => {
    const today = DateTime.now();
    const reduxValueForToday = today.toSQLDate() + "T00:00:00.00Z";
    props.change("applicationDate", reduxValueForToday);
  }, [props]);

  useEffect(() => {
    if (currentAddress?.zip?.length === constants.ZIP_LENGTH) {
      const result = zipcodes.lookup(currentAddress.zip);
      props.change("currentAddress.city", result?.city);
      props.change("currentAddress.state", result?.state);
    } else {
      props.change("currentAddress.city", "");
      props.change("currentAddress.state", "");
    }
  }, [currentAddress?.zip, props]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="seh-form-container">
        <div className="seh-form-container__content">
          <h2>{Translations["applicant-information"]}</h2>
          <div className="seh-form-container__content__group">
            <Field
              name="firstName"
              label={Translations["first-name"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
            <Field
              name="middleName"
              label={Translations["middle-name"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              validate={validations.checkSpecialCharacters}
            />
            <Field
              name="lastName"
              label={Translations["last-name"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name="birthDate"
              label={Translations["date-of-birth"]}
              component={ReduxDatepicker}
              className="seh-form-container__content__group__field"
              required
              onlyPast
              validate={[
                validations.undefined,
                globalValidators.onlyPast,
                globalValidators.checkBirthDateValidation,
              ]}
            />
          </div>

          <div className="seh-form-container__content__group">
            <Field
              name="ssn"
              label={Translations["ssn"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              placeholder="e.g. 123-45-6789"
              validate={[validations.undefined, validations.ssnValidation]}
            />
            <Field
              name="fein"
              label={Translations["fein"]}
              className="seh-form-container__content__group__field"
              type="number"
              component={ReduxInput}
              validate={[
                validations.negativeValue,
                numberValidation.exactDigits(
                  Translations["error-fein-number"],
                  9
                ),
              ]}
            />
          </div>

          <Field name="applicationDate" component="input" type="hidden" />

          <div className="seh-form-container__content__group">
            <Field
              name="positionAppliedFor"
              label={Translations["position-applied-for"]}
              component={ReduxInput}
              className="seh-form-container__content__group__field"
              required
              validate={validations.undefined}
            />
          </div>

          <div className="seh-form-container__content__group">
            <Field
              name={`currentAddress.zip`}
              type="number"
              onInput={(event: any) => {
                if (event.target.value.length > constants.ZIP_LENGTH) {
                  event.target.value = event.target.value.slice(
                    0,
                    constants.ZIP_LENGTH
                  );
                }
              }}
              label={Translations["zip"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                numberValidation.exactDigits(Translations["error-zip"], 5),
                validations.zipValidation,
              ]}
            />

            <Field
              name={`currentAddress.city`}
              type="text"
              label={Translations["city"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              readOnly
              validate={validations.undefined}
            />
            <Field
              name={`currentAddress.state`}
              label={Translations["state"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              readOnly
              validate={validations.undefined}
            />
          </div>
          <div className="seh-form-container__content__group">
            <Field
              name={`currentAddress.address`}
              type="text"
              label={Translations["address"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              required
              validate={[
                validations.undefined,
                validations.checkSpecialCharacters,
              ]}
            />
            <Field
              name={`phone`}
              type="number"
              label={Translations["phone-number"]}
              className="seh-form-container__content__group__field"
              component={ReduxInput}
              placeholder="e.g. 9876543210"
              required
              validate={[
                validations.undefined,
                numberValidation.exactDigits(
                  Translations["error-phone-number"],
                  10
                ),
              ]}
            />
          </div>

          <Fields name="emergencyContacts" component={EmergencyContacts} />
          <div
            className="seh-button-submit"
            style={{ justifyContent: "flex-end" }}
          >
            <Button type="button" onClick={handleSubmit}>
              {Translations["next-page"]}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "SEForm",
  destroyOnUnmount: false,
  onSubmitFail: () => {
    scrollToFirstValidationError();
  },
})(Step1);
