import { useCallback, useEffect, useMemo, useRef } from 'react'
import { stringifyDatePart } from '../../../utils'
import './DatePart.scss'
import classNames from 'classnames'
type typeOfPart = 'month' | 'day' | 'year'
export type DatePartProps = {
  type: typeOfPart
  value?: number
  hasFocus?: boolean
  onBlur?: (index: number) => void
  partIndex?: number
  setFocus?: (index: number) => void
  toShow?: string
  className?: string
}
const DatePart: React.FC<DatePartProps> = ({
  type,
  value,
  setFocus,
  hasFocus,
  partIndex,
  onBlur,
  toShow,
  className,
}) => {
  const partRef = useRef<HTMLSpanElement>(null)
  const onFocus = useCallback(
    (ev: React.FocusEvent<HTMLSpanElement>) => {
      ev.preventDefault()
      setFocus?.(partIndex!)
    },
    [partIndex, setFocus],
  )

  useEffect(() => {
    partRef.current?.[hasFocus ? 'focus' : 'blur']()
  }, [hasFocus])

  const span = useMemo(
    () => (
      <span
        ref={partRef}
        onFocus={onFocus}
        onBlur={() => onBlur?.(partIndex!)}
        onMouseDown={(ev) => ev.stopPropagation()}
        className={classNames('date-part', className)}
        tabIndex={0}
        onKeyDown={(ev) => ev.preventDefault()}
        contentEditable
        suppressContentEditableWarning
      >
        {toShow ? toShow : stringifyDatePart(value!)}
      </span>
    ),
    [onFocus, className, toShow, value, onBlur, partIndex],
  )
  return (
    <>
      {span} {type === 'year' ? null : '/'}
    </>
  )
}

export default DatePart
