import React from 'react';
import classNames from 'classnames';
import './ToggleButton.scss';
import { DefaultInputProps } from '../Input/Input';
import { PatchService } from '../../../services/patchService';

export type ToggleButtonProps = {
  optionOne?: string;
  optionTwo?: string;
  label?: string;
  color?: string;
  error?: string;
} & DefaultInputProps;

const ToggleButton: React.FC<ToggleButtonProps> = (
  props: ToggleButtonProps
) => {
  const {
    checked,
    label,
    className,
    required,
    optionOne = 'No',
    optionTwo = 'Yes',
    color = '#011077',
    error,
    ...rest
  } = props;

  return (
    <div
      tabIndex={0}
      onBlur={() => PatchService.patch()}
      className={classNames('seh-toggle-button', className)}
    >
      {label && (
        <label>
          {label}
          {required ? <span className="seh-required">*</span> : null}
        </label>
      )}
      <label
        style={{ color, borderColor: color }}
        className="seh-toggle-button__switch"
      >
        <input
          checked={checked}
          required={required}
          type="checkbox"
          {...rest}
        />
        <span
          style={{ backgroundColor: color }}
          className="seh-toggle-button__switch__slider"
        />
        <span
          className={classNames('seh-toggle-button__switch__choice', {
            'seh-toggle-button__switch__choice--checked': !checked,
          })}
        >
          {optionOne}
        </span>
        <span
          className={classNames('seh-toggle-button__switch__choice', {
            'seh-toggle-button__switch__choice--checked': checked,
          })}
        >
          {optionTwo}
        </span>
      </label>
      {error && <small className="seh-container__error">{error}</small>}
    </div>
  );
};

export default ToggleButton;
