import { PATCH, LOAD_CLEAN_DATA } from '../actions/types'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case PATCH:
      return {
        data: performNestedUpdate(state.data, action.payload),
      }
    case LOAD_CLEAN_DATA:
      return {
        data: action.payload,
      }
    default:
      return state
  }
}

export default reducer

function performNestedUpdate(obj, upd) {
  const newObject = JSON.parse(JSON.stringify(obj || {}))

  for (let prop in upd) {
    if (typeof upd[prop] === 'object' || Array.isArray(upd[prop])) {
      newObject[prop] = performNestedUpdate(newObject[prop], upd[prop])
    } else {
      newObject[prop] = upd[prop]
    }
  }

  return newObject
}
