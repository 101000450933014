import {
  STEP_COMPLETED,
  STEPS_FETCHED,
  STEP_UNCOMPLETED,
} from "../actions/types";

const INITIAL_STATE = [];

const stepsCompletedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STEP_COMPLETED:
      return state.find((step) => step === action.payload)
        ? state
        : [...state, action.payload].sort();
    case STEP_UNCOMPLETED:
      return state.filter((step) => step !== action.payload);
    case STEPS_FETCHED:
      return action.payload;
    default:
      return state;
  }
};

export default stepsCompletedReducer;
