import { Provider } from "react-redux";
import { store } from "./store";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import toastConfig from "./utils/toastConfig";
import routes from "./router/routes";
import Routes from "./router/Routes/Routes";

import "./index.scss";

const App = () => {
  const history = createBrowserHistory();
  toastConfig();

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes routes={routes} />
      </Router>
    </Provider>
  );
};

export default App;
