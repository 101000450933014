import { toast } from 'react-toastify';

const toastConfig = () => {
  toast.configure({
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
  });
};

export default toastConfig;
