import { PatchService } from "../../services/patchService";
import Input from "../ui/Input/Input";

export default ({ input, meta: { touched, error }, ...rest }) => {
  const blur = () => {
    input.onBlur();
    if (input.value !== undefined) {
      PatchService.patch();
    }
  };
  return (
    <Input
      {...input}
      {...rest}
      {...(touched && { error })}
      onBlur={(_) => {
        blur();
      }}
    />
  );
};
