import React from "react";
import Datepicker, {
  DatepickerProps,
} from "../ui/Datepicker/Datepicker.component";
import { WrappedFieldProps } from "redux-form";

type ReduxDatepickerProps = WrappedFieldProps & DatepickerProps;
const ReduxDatepicker: React.FC<
  ReduxDatepickerProps & { formName: string }
> = ({ input, meta: { touched, error }, ...rest }) => (
  <Datepicker {...(touched && { error })} {...input} {...rest} />
);

export default ReduxDatepicker;
