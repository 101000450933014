import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import currentPageReducer from "./currentPageReducer";
import stepsCompletedReducer from "./stepsCompletedReducer";
import loadInitialDataReducer from "./loadInitialDataReducer";

export default combineReducers({
  form: formReducer,
  currentPage: currentPageReducer,
  stepsCompleted: stepsCompletedReducer,
  initialValues: loadInitialDataReducer,
});
