import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import MultiSelect, { MultiSelectProps } from '../ui/MultiSelect/MultiSelect';

type ReduxMultiSelectProps = WrappedFieldProps & MultiSelectProps;

const ReduxMultiSelect: React.FC<ReduxMultiSelectProps> = ({
  input,
  meta: { touched, error },
  meta,
  ...rest
}) => {
  const { onChange, ...inputRest } = input;

  return (
    <MultiSelect
      onChange={(values) => onChange(values)}
      {...inputRest}
      {...rest}
      {...(touched && { error })}
    />
  );
};

export default ReduxMultiSelect;
