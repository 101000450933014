import { constants } from "../../config";
import { PatchService } from "../../services/patchService";
import httpClient from "../httpClient";

const addEmploymentHistory = async (token: string) => {
  return await httpClient.post(`form/drivers/${token}/employment-history`);
};

const prefillEmploymentHistory = async (dotNumber: string) => {
  return await httpClient.post(`/employment-history/prefill`, {
    dotNumber,
  });
};

const updateEmploymentHistory = async (
  id: string,
  data: Record<string, any>
) => {
  return await httpClient.patch(`/employment-history/${id}`, data);
};

const removeEmploymentHistory = async (data: Record<string, string>) => {
  const token = PatchService.token;
  return await httpClient.delete(`form/drivers/${token}/employment-history`, {
    data: data,
  });
};

const fillEmploymentHistoryForm = async (
  employmentHistoryId: string,
  driverId: string,
  data: Record<string, any>
) => {
  await httpClient.post(
    `employment-history/${employmentHistoryId}/drivers/${driverId}/form`,
    { ...data },
    {
      headers: {
        "x-api-key": constants.X_API_KEY,
      },
    }
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addEmploymentHistory,
  prefillEmploymentHistory,
  updateEmploymentHistory,
  removeEmploymentHistory,
  fillEmploymentHistoryForm,
};
