import {
  JUMPED_TO_PAGE,
  NEXT,
  LOAD_CLEAN_DATA,
  STEP_COMPLETED,
  STEPS_FETCHED,
  PATCH,
  STEP_UNCOMPLETED,
} from "./types";

export const jumpToPage = (pageNumber) => ({
  type: JUMPED_TO_PAGE,
  payload: pageNumber,
});
export const nextPage = () => ({ type: NEXT });

export const patch = (data) => ({ type: PATCH, payload: data });
export const load = (data) => ({ type: LOAD_CLEAN_DATA, payload: data });

export const uncompleteStep = (step) => ({
  type: STEP_UNCOMPLETED,
  payload: step,
});

export const saveStepsCompleted = (step) => ({
  type: STEP_COMPLETED,
  payload: step,
});

export const saveFetchedCompletedSteps = (stepsCompleted) => ({
  type: STEPS_FETCHED,
  payload: stepsCompleted,
});
